import React, { useEffect, useMemo, useRef } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Redirect } from 'react-router-dom';
import dayjs from 'dayjs';
import ReactToPrint from 'react-to-print';

import { Pane, CurrencyText, ContentContainer } from 'components/common';
import PaymentDates from 'components/paymentPlans/PaymentDates';
import Button, { buttonColor, buttonStyle, buttonSize } from 'components/common/Button';
import SuccessIcon from 'assets/icons/successIcon.svg';
import LogoIcon from 'assets/icons/logoIcon.svg';
import PrintIcon from 'assets/icons/print.svg';
import {
  ACCOUNT_DATE_FORMAT,
  REPAYMENT_OPTIONS,
  CONFIRMATION_OPTIONS_ID,
  PLAN_FREQUENCY,
  PAYMENT_METHOD_OPTIONS
} from 'constants/constants';
import { PAYMENT_AUTHORIZATION } from 'constants/disclosures';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import routes from 'constants/routesPaths';
import {
  useSelectAccountDetails,
  useSelectSelectedPlan,
  useAnalytics,
  useSelectPaymentMethod,
  useSelectPaymentInfo,
  useSelectNewPlanId,
  useSelectPaymentDates
} from 'hooks';

const PaymentPlanSuccess = () => {
  const intl = useIntl();
  const { referenceNumber, originalCreditor, remainingBalance } = useSelectAccountDetails();
  const { selectedPaymentMethod } = useSelectPaymentMethod();
  const { last4, cardType } = useSelectPaymentInfo();
  const { paymentSchedule } = useSelectPaymentDates();
  const { trackEvent } = useAnalytics();
  const componentRef = useRef();

  const {
    selectedRepaymentOption,
    planSummary,
    selectedDate,
    selectedPlan,
    selectedAmount,
    selectedFrequency
  } = useSelectSelectedPlan();

  const { planId } = useSelectNewPlanId();

  const messages = defineMessages({
    bankAccount: { id: 'paymentMethod.bankMinified' },
    endingIn: { id: 'confirmPage.endingIn' },
    paymentMethod: { id: 'confirmPage.paymentMethod' },
    transactionId: { id: 'viewPaymentConfirmation.transactionId' },
    originalCreditor: { id: 'viewPaymentConfirmation.originalCreditor' },
    referenceNumber: { id: 'viewPaymentConfirmation.referenceNumber' },
    frequency: { id: 'viewPaymentConfirmation.frequency' },
    every: { id: 'viewPaymentConfirmation.every' },
    ofTheMonth: { id: 'paymentFrequency.ofTheMonth' },
    backToHome: { id: 'viewPaymentConfirmation.backToHome' }
  });

  const messageSuccess = useMemo(
    () =>
      selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan
        ? intl.formatMessage({ id: 'viewPaymentConfirmation.createPlan.messageSuccess' })
        : intl.formatMessage({ id: 'viewPaymentConfirmation.payment.messageSuccess' }),
    [selectedRepaymentOption, intl]
  );

  const paymentSuccess = useMemo(
    () =>
      selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan
        ? intl.formatMessage({ id: 'viewPaymentConfirmation.createPlan.paymentSuccess' })
        : intl.formatMessage({ id: 'viewPaymentConfirmation.payment.paymentSuccess' }),
    [selectedRepaymentOption, intl]
  );

  const dateLabel = useMemo(
    () => intl.formatMessage({ id: `${CONFIRMATION_OPTIONS_ID[selectedRepaymentOption]}.date` }),
    [selectedRepaymentOption, intl]
  );

  const paymentLabel = useMemo(
    () => intl.formatMessage({ id: `${CONFIRMATION_OPTIONS_ID[selectedRepaymentOption]}.payment` }),
    [selectedRepaymentOption, intl]
  );

  const formattedDate = useMemo(() => dayjs(selectedDate).format(ACCOUNT_DATE_FORMAT), [
    selectedDate
  ]);

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.viewPaymentConfirmationPage, { option: selectedRepaymentOption });
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!planId) {
    return <Redirect to={routes.repaymentOptions} />;
  }

  return (
    <ContentContainer>
      <div className="success-page-container">
        <div ref={componentRef}>
          <LogoIcon className="print-soc-logo" />
          <span className="success-container">
            <div className="success-icon">
              <SuccessIcon />
            </div>
            <h1 className="success-title">{messageSuccess}</h1>
            <span className="success-message">{paymentSuccess}</span>
          </span>
          <Pane className="success-content">
            <span className="success-info-container">
              {intl.formatMessage(messages.transactionId)}
              <span className="font-weight-bold w-50 text-right">{planId}</span>
            </span>
            {selectedRepaymentOption !== REPAYMENT_OPTIONS.createPlan && (
              <span className="success-info-container">
                {dateLabel}
                <div className="font-weight-bold w-50 text-right">{formattedDate}</div>
              </span>
            )}
            <span className="success-info-container">
              {intl.formatMessage(messages.originalCreditor)}
              <span className="font-weight-bold w-50 text-right">{originalCreditor}</span>
            </span>
            {selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan && (
              <span className="success-info-container">
                {intl.formatMessage(messages.referenceNumber)}
                <span className="font-weight-bold w-50 text-right">{referenceNumber}</span>
              </span>
            )}
            <span className="success-info-container no-border">
              {paymentLabel}
              {selectedRepaymentOption === REPAYMENT_OPTIONS.payInFull && (
                <span className="font-weight-bold w-50 text-right">
                  <CurrencyText value={remainingBalance} />
                </span>
              )}
              {selectedRepaymentOption === REPAYMENT_OPTIONS.partialPayment && (
                <span className="font-weight-bold w-50 text-right">
                  <CurrencyText value={selectedAmount} />
                </span>
              )}
              {selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan && (
                <span className="font-weight-bold w-50 text-right">{planSummary}</span>
              )}
            </span>
            {selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan && (
              <PaymentDates paymentSchedule={paymentSchedule} />
            )}
            {selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan && (
              <span className="success-info-container">
                {intl.formatMessage(messages.frequency)}
                <span className="font-weight-bold w-50 text-right">
                  {intl.formatMessage(messages.every)} {selectedFrequency.value}
                  {selectedPlan.planScheduleFrequency === PLAN_FREQUENCY.monthly && (
                    <>{intl.formatMessage({ id: 'paymentFrequency.ofTheMonth' })}</>
                  )}
                </span>
              </span>
            )}
            <span className="success-info-container">
              {intl.formatMessage(messages.paymentMethod)}
              <span className="font-weight-bold w-50 text-right w-auto">
                <div className="d-flex justify-content-end">
                  {PAYMENT_METHOD_OPTIONS.card === selectedPaymentMethod ? (
                    <p className="card-type">{cardType}</p>
                  ) : (
                    <span>{intl.formatMessage(messages.bankAccount)}</span>
                  )}
                  <>
                    <div className="mx-1">{intl.formatMessage(messages.endingIn)}</div>
                    {last4}
                  </>
                </div>
              </span>
            </span>
          </Pane>

          <section className="payment-authorization-container">
            <span className="payment-authorization">
              {PAYMENT_METHOD_OPTIONS.card === selectedPaymentMethod
                ? PAYMENT_AUTHORIZATION.card
                : PAYMENT_AUTHORIZATION.ACH}
              {PAYMENT_AUTHORIZATION.print}
            </span>
          </section>
        </div>
        <div className="d-flex align-items-center mt-3">
          <ReactToPrint
            trigger={() => (
              <Button
                buttonStyle={buttonStyle.PRIMARY}
                size={buttonSize.XSMALL}
                color={buttonColor.WHITE}
              >
                <PrintIcon className="mr-2" />
                {intl.formatMessage({ id: 'viewPaymentConfirmation.printReceipt' })}
              </Button>
            )}
            content={() => componentRef.current}
          />
        </div>
        <Button
          className="success-page-back-button"
          linkTo={routes.index}
          buttonStyle={buttonStyle.SECONDARY}
          size={buttonSize.SMALL}
          color={buttonColor.BLACK}
          title={intl.formatMessage(messages.backToHome)}
          ariaLabel={intl.formatMessage(messages.backToHome)}
        />
      </div>
    </ContentContainer>
  );
};

export default PaymentPlanSuccess;
