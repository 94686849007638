import routes from 'constants/routesPaths';

export const navigationPrimaryOptions = [
  {
    titleId: 'sidemenu.home',
    ariaLabel: 'sidemenu.homeAria',
    route: routes.index
  },
  {
    titleId: 'sidemenu.documents',
    ariaLabel: 'sidemenu.documents',
    route: routes.documents
  }
];

export const navigationSecondaryOptions = [
  {
    titleId: 'sidemenu.disclosures',
    ariaLabel: 'sidemenu.disclosuresAria',
    route: routes.homeDisclosures
  }
];
