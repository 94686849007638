import React, { memo, useState, useEffect } from 'react';
import { func } from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import { REJECTED, PENDING } from 'constants/actionStatusConstants';
import { MIXPANEL_EVENTS, MIXPANEL_LOCATIONS } from 'constants/mixpanelEvents';
import { Loading, Tooltip, DateInput, Input } from 'components/common';
import Button, { buttonColor, buttonStyle, buttonSize } from 'components/common/Button';
import { useStatus, useForm, useTextInputProps, useAnalytics } from 'hooks';
import { formatDate, formattedDateInput, acceptOnlyDigits } from 'utils/helpers';
import { login, accounts } from 'state/actions/userActions';
import AlertIcon from 'assets/icons/alertIcon.svg';

const messages = defineMessages({
  referenceNumber: { id: 'login.form.referenceNumber' },
  birthDate: { id: 'login.form.birthDate' },
  ssn: { id: 'login.form.ssn' },
  referenceNumberPlaceholder: { id: 'login.form.referenceNumberPlaceholder' },
  birthDatePlaceholder: { id: 'login.form.birthDatePlaceholder' },
  ssnPlaceholder: { id: 'login.form.ssnPlaceholder' },
  lastName: { id: 'login.form.lastName' },
  lastNamePlaceholder: { id: 'login.form.lastNamePlaceholder' },
  tooltipTitle: { id: 'login.tooltip.title' },
  tooltipDescription: { id: 'login.tooltip.description' }
});

const fields = {
  lastName: 'lastName',
  referenceNumber: 'referenceNumber',
  birthDate: 'dateOfBirth',
  ssn: 'ssnLastFour'
};

export const LoginForm = ({ onSubmit }) => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();

  const loginTitle = intl.formatMessage({ id: 'login.form.submit' });
  const [date, setDate] = useState();
  const [disabled, setDisabled] = useState(true);
  const { status: loginStatus } = useStatus(login);
  const { status: accountsStatus } = useStatus(accounts);
  const {
    values,
    errors,
    handleValueChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    activeFields,
    touched
  } = useForm(
    {
      onSubmit,
      validateOnChange: true,
      validateOnBlur: true
    },
    [onSubmit]
  );

  const inputProps = useTextInputProps(
    handleValueChange,
    handleFocus,
    handleBlur,
    values,
    errors,
    activeFields,
    touched
  );

  const handleOnInput = (e, maxLength) => {
    e.target.value = e.target.value.slice(0, maxLength);
  };

  const onSSNBlur = key => {
    values.ssnLastFour && trackEvent(MIXPANEL_EVENTS.fillSSN);
    handleBlur(key);
  };

  const onReferenceBlur = key => {
    values.referenceNumber > 1 && trackEvent(MIXPANEL_EVENTS.fillReference);
    handleBlur(key);
  };

  const onLastNameBlur = key => {
    values.lastName && trackEvent(MIXPANEL_EVENTS.fillLastName);
    handleBlur(key);
  };

  const onDateBlur = () => {
    if (date?.length === 10) {
      trackEvent(MIXPANEL_EVENTS.fillDOB);
    } else {
      setDate('');
    }
  };

  useEffect(() => {
    values.dateOfBirth = null;
    if (date?.length === 10) {
      values.dateOfBirth = formatDate(new Date(date));
    }
  }, [date]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const compactedValues = compact(Object.values(values));

    setDisabled(
      compactedValues.length < 3 ||
        ((compactedValues.length >= 3 &&
          (!isEmpty(values.ssnLastFour) && values.ssnLastFour.length !== 4)) ||
          (!isEmpty(values.dateOfBirth) && values.dateOfBirth.length !== 10))
    );
  }, [date, values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form className="login-form-component" onSubmit={handleSubmit}>
      <div>
        <Input
          name="lastName"
          label={intl.formatMessage(messages.lastName)}
          placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
          {...inputProps(fields.lastName)}
          onBlur={onLastNameBlur}
        />
      </div>
      <div className="position-relative">
        <Input
          name="referenceNumber"
          type="number"
          min={1}
          step={1}
          onKeyPress={acceptOnlyDigits}
          inputMode="numeric"
          onInput={e => handleOnInput(e, 12)}
          label={intl.formatMessage(messages.referenceNumber)}
          placeholder={intl.formatMessage(messages.referenceNumberPlaceholder)}
          {...inputProps(fields.referenceNumber)}
          onBlur={onReferenceBlur}
        />
        <div>
          <Tooltip
            afterShow={() =>
              trackEvent(MIXPANEL_EVENTS.refNumberTooltip, { Location: MIXPANEL_LOCATIONS.login })
            }
            id="referenceNumberTooltip"
            ariaLabel="Information about the reference number"
            title={intl.formatMessage(messages.tooltipTitle)}
            description={intl.formatMessage(messages.tooltipDescription)}
          />
        </div>
      </div>
      <div>
        <DateInput
          label={intl.formatMessage(messages.birthDate)}
          placeholder={intl.formatMessage(messages.birthDatePlaceholder)}
          value={date}
          onChange={({ value }) => setDate(formattedDateInput(value))}
          onBlur={onDateBlur}
        />
      </div>
      <div>
        <Input
          name="ssnLastFour"
          type="number"
          min={1}
          step={1}
          onKeyPress={acceptOnlyDigits}
          inputMode="numeric"
          onInput={e => handleOnInput(e, 4)}
          label={intl.formatMessage(messages.ssn)}
          placeholder={intl.formatMessage(messages.ssnPlaceholder)}
          error={!isEmpty(values.ssnLastFour) && values.ssnLastFour.length != 4 && 'ssn.invalid'}
          {...inputProps(fields.ssn)}
          onBlur={onSSNBlur}
        />
      </div>
      {loginStatus === REJECTED && (
        <div className="login-error">
          <AlertIcon />
          {intl.formatMessage({ id: 'login.form.error' })}
        </div>
      )}
      <p className="text-center">{intl.formatMessage({ id: 'login.form.submitFields' })}</p>
      {loginStatus === PENDING || accountsStatus === PENDING ? (
        <Loading />
      ) : (
        <Button
          type="submit"
          className="w-100"
          ariaLabel="Login"
          buttonStyle={buttonStyle.PRIMARY}
          size={buttonSize.MEDIUM}
          color={buttonColor.GREEN}
          title={loginTitle}
          isDisabled={disabled}
        />
      )}
    </form>
  );
};

LoginForm.propTypes = {
  onSubmit: func.isRequired
};

export default memo(LoginForm);
