const routes = {
  accountError: '/account-error',
  confirmPaymentPage: '/confirm-payment',
  createPaymentPlan: '/payment-plan/create',
  disclosures: '/disclosures',
  documents: '/documents',
  homeDisclosures: '/home-disclosures',
  index: '/',
  login: '/login',
  partialPayment: '/payment-plan/partial',
  paymentErrorPage: '/payment-plan/error',
  paymentPlanSuccess: '/payment-plan/success',
  repaymentOptions: '/repayment-options',
  setupPlanPayment: '/payment-plan/payment'
};

export default routes;
