import { useSelector, shallowEqual } from 'react-redux';

const useSelectSideMenuInfo = () =>
  useSelector(
    ({ accounts: { details } }) => ({
      firstName: details[0]?.accountholderFirstName,
      lastName: details[0]?.accountholderLastName,
      referenceNumber: details[0]?.referenceNumber
    }),
    shallowEqual
  );

export default useSelectSideMenuInfo;
