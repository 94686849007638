import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';

import { useSession, useDispatch, useAnalytics } from 'hooks';
import routes from 'constants/routesPaths';
import { logout } from 'state/actions/userActions';
import { Button } from 'components/common';
import LegalInformation from 'components/legal/LegalInformation';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';

const { SOC_WEB_CONTACT_US } = process.env;

const AccountErrorPage = () => {
  const intl = useIntl();
  const { authenticated } = useSession();
  const { trackEvent } = useAnalytics();
  const logoutRequest = useDispatch(logout);

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.viewAccountSuppressedPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!authenticated) {
    return <Redirect to={{ pathname: routes.login, state: { hideMenu: true } }} />;
  }

  return (
    <div className="account-error-container">
      <div className="account-error-contact-us">
        <FormattedMessage
          id="account.suppressed"
          values={{
            contactUsLink: (
              <a className="account-error-link" href={SOC_WEB_CONTACT_US}>
                {intl.formatMessage({ id: 'legal.contactUs' })}
              </a>
            ),
            contactUsNumber: <b>{intl.formatMessage({ id: 'legal.contactUsNumber' })}</b>
          }}
        />
      </div>
      <Button
        className="my-4"
        onClick={logoutRequest}
        buttonStyle="primary"
        size="large"
        color="green"
        title={intl.formatMessage({ id: 'legal.tryAgain' })}
        ariaLabel={intl.formatMessage({ id: 'legal.tryAgain' })}
      />
      <LegalInformation />
    </div>
  );
};

export default AccountErrorPage;
