import React from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { string } from 'prop-types';

import {
  navigationPrimaryOptions,
  navigationSecondaryOptions
} from 'constants/navigationOptionsConstants';
import { useSelectSideMenuInfo } from 'hooks';
import AvatarIcon from 'assets/icons/avatarIcon.svg';
import Logout from 'components/user/Logout';
import SideMenuSection from './SideMenuSection';

const SideMenu = ({ className = '' }) => {
  const intl = useIntl();
  const { firstName, lastName, referenceNumber } = useSelectSideMenuInfo();

  return (
    <section className={cn('side-menu', className)}>
      <SideMenuSection options={navigationPrimaryOptions} />

      <section className="user-info">
        <div className="d-flex align-items-center mb-2">
          <AvatarIcon />
          <span className="ml-3">{firstName}</span>
          <span className="ml-1">{lastName}</span>
        </div>
        <div className="reference mt-2">
          <span className="reference-label mr-1">
            {intl.formatMessage({ id: 'sidemenu.referenceNumber' })}
          </span>
          <span className="reference-number">{referenceNumber}</span>
        </div>
      </section>

      <SideMenuSection options={navigationSecondaryOptions} />

      <section className="logout">
        <Logout />
      </section>
    </section>
  );
};

SideMenu.propTypes = {
  className: string
};

export default SideMenu;
