import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import Button, { buttonStyle, buttonSize, buttonColor } from 'components/common/Button';
import { ContentContainer } from 'components/common';
import routes from 'constants/routesPaths';
import { useAnalytics } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';

import ErrorIcon from 'assets/icons/errorIcon.svg';

const PaymentErrorPage = () => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.viewPaymentErrorPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentContainer>
      <div className="container payment-error-container">
        <ErrorIcon />
        <h1>{intl.formatMessage({ id: 'paymentError.title' })}</h1>
        <span>{intl.formatMessage({ id: 'paymentError.description' })}</span>
        <Button
          className="my-4"
          buttonStyle={buttonStyle.SECONDARY}
          size={buttonSize.SMALL}
          color={buttonColor.BLACK}
          title={intl.formatMessage({ id: 'paymentError.tryAgain' })}
          ariaLabel={intl.formatMessage({ id: 'paymentError.tryAgain' })}
          linkTo={routes.confirmPaymentPage}
        />
      </div>
    </ContentContainer>
  );
};

export default PaymentErrorPage;
