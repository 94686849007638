export default {
  'img.humanStanding.alt': 'Human standing',
  'img.logo.alt': 'Spring Oaks Capital Logo',

  'login.backWebsite': 'Back to website',
  'login.welcome': 'Welcome!',
  'login.validate': 'Please validate your identity to enter the Portal.',

  'login.form.lastName': 'Last Name',
  'login.form.lastNamePlaceholder': 'Enter your last name',
  'login.form.referenceNumber': 'Reference Number',
  'login.form.referenceNumberPlaceholder': 'Enter your reference number',
  'login.form.birthDate': 'Date of Birth',
  'login.form.birthDatePlaceholder': 'MM/DD/YYYY',
  'login.form.ssn': 'Last Four of SSN',
  'login.form.ssnPlaceholder': 'Enter the last four numbers of SSN',
  'login.form.submitFields': 'You must submit at least 3 fields to access.',
  'login.form.submit': 'Login',
  'login.form.error': 'Please check your data and try again',
  'login.tooltip.title': 'What is the Reference Number?',
  'login.tooltip.description':
    'The Reference Number is the unique number we use to identify your account. You can find your reference number of the letter we mailed you and on each email we send you.',

  // Disclosures
  'disclosures.title': 'Disclosures',
  'footer.continue': 'Continue',
  'footer.continueAria': 'Continue to Portal',
  'footer.login': 'Login',
  'footer.loginAria': 'Back to Login',

  // Side Menu
  'sidemenu.home': 'Home',
  'sidemenu.homeAria': 'Go to Home Page',
  'sidemenu.disclosures': 'Disclosures',
  'sidemenu.disclosuresAria': 'Go to Disclosures Page',
  'sidemenu.documents': 'Documents',
  'sidemenu.documentsAria': 'Go to Documents Page',
  'sidemenu.referenceNumber': 'Ref. Number:',
  'sidemenu.logout': 'Log Out',

  // Documents
  'documents.title': 'Documents',
  'documents.originalCreditor': 'Original Creditor',
  'documents.referenceNumber': 'Ref. Number: ',
  'documents.openedDate': 'Date Account Opened',
  'documents.accountNumber': 'Original Account Number',

  // Accounts
  'accounts.remainingBalance': 'Remaining Balance',
  'accounts.paid': 'You already paid ',
  'accounts.paidOf': 'of',
  'accounts.originalBalance': 'Original Balance',
  'accounts.originalCreditor': 'Original Creditor',
  'accounts.referenceNumber': 'Reference Number',
  'accounts.openedDate': 'Date Account Opened',
  'accounts.accountNumber': 'Original Account Number',
  'accounts.hideDetails': 'Hide Account Details',
  'accounts.showDetails': 'Account Details',

  // Legal
  'legal.debtCollector':
    'This is a communication from a debt collector. This is an attempt to collect a debt and any information obtained will be used for that purpose.',
  'legal.knowYourRights': 'Know your rights',
  'legal.troubleContact':
    'If you have trouble accessing your account\nplease call us at 866-539-7525 or through the {contactUsLink} page.\n© 2021 Spring Oaks Capital, LLC, All Rights Reserved.',
  'legal.contactUs': 'Contact Us',
  'legal.contactUsNumber': '866-539-7525',
  'legal.tryAgain': 'Try Again',

  // Repayment Options
  'selectRepaymentOption.createPaymentPlan': 'Create a Payment Plan',
  'selectRepaymentOption.partialPayment': 'Make a Partial Payment',
  'selectRepaymentOption.payInFull': 'Pay in Full',
  'selectRepaymentOption.title': 'Choose Your Repayment Option',

  // Partial Payment
  'partialPaymentPage.amount': 'Amount',
  'partialPaymentPage.amountError': 'Amount must be equal or less than Remaining Balance',
  'partialPaymentPage.amountErrorOne': 'Amount must be $1 minimum',
  'partialPaymentPage.amountSummary': '1 payment of {selectedAmount}',
  'partialPaymentPage.enterAmount': 'Or enter the exact amount you want to pay',
  'partialPaymentPage.percentageAria': 'Make a partial payment of {percentage}%',
  'partialPaymentPage.subtitle': 'Select the percentage you want to pay',
  'partialPaymentPage.title': 'Choose your Payment Amount',

  // Payment Options
  'paymentOptionsBlock.title': 'The journey to being debt-free starts with a single step.',
  'paymentOptionsBlock.desc': 'Start creating your payment plan or make a single payment.',
  'paymentOptionsBlock.cta': 'More Payment Options',
  'paymentOptionsBlock.minifiedCTA': 'Payment Options',
  'createPlan.frequencyTitle': 'Choose Your Payment Frequency',
  'createPlan.planTitle': 'Select the option that works best for you',
  'createPlan.continue': 'Continue',
  'createPlan.goBackAria': 'Go back to previous page',
  'createPlan.goBack': 'Back',
  'createPlan.continueAria': 'Continue to setup payment',
  'createPlan.disclaimer':
    '*The final payment of your plan may be less than your other payments to reflect the remaining balance and avoid overpayment',
  'paymentFrequency.weekly': 'Weekly',
  'paymentFrequency.biweekly': 'Every 2 weeks',
  'paymentFrequency.monthly': 'Monthly',
  'paymentFrequency.ofTheMonth': ' of the month',
  'paymentFrequency.biweeklyLabel': 'Bi-weekly',
  'paymentFrequency.ariaWeekly': 'Select weekly frequency',
  'paymentFrequency.ariaBiweekly': 'Select every 2 weeks frequency',
  'paymentFrequency.ariaMonthly': 'Select monthly frequency',
  'setupPayment.title': 'Set up your Payments',
  'setupPayment.selectDate': 'When would you like your payments to begin?',
  'setupPayment.selectDateLabel': 'Select a date*',
  'setupPayment.selectDateError': 'You must select a Start Date to continue',
  'setupPayment.selectFrequency': 'Frequency',
  'setupPayment.everyOther': 'Every other',
  'setupPayment.every': 'Every',
  'setupPayment.daysDisclaimer':
    '*You can select a date up to {days} {days, plural, one {day} other {days}} from today.',
  'backBanner.originalCreditor': 'Original Creditor',
  'backBanner.remainingBalance': 'Remaining Balance',
  'backBanner.refNumber': 'Ref. Number',
  'backBanner.summaryAria': 'View/Hide banner details',
  'backBanner.summary': 'Summary',
  'backBanner.viewLess': 'View less',
  'termUnit.payments': 'Payments',

  // Payment Method
  'paymentMethod.card': 'Credit / Debit Card',
  'paymentMethod.bank': 'Bank Account (ACH)',
  'paymentMethod.bankMinified': 'Bank Account',

  // Plan Types
  'planType.partialPayment': 'Partial Payment',
  'planType.payInFull': 'Pay in Full',
  'planType.paymentPlan': 'Payment Plan',

  // Days
  'days.monday': 'Monday',
  'days.tuesday': 'Tuesday',
  'days.wednesday': 'Wednesday',
  'days.thursday': 'Thursday',
  'days.friday': 'Friday',
  'days.saturday': 'Saturday',
  'days.sunday': 'Sunday',

  // Confirm Page
  'confirmPage.every': 'Every',
  'confirmPage.frequency': 'Frequency',
  'confirmPage.authorize': 'Authorize',
  'confirmPage.paymentPlan': 'Payment Plan',
  'confirmPage.paymentDate': 'Payment Date',
  'confirmPage.paymentMethod': 'Payment Method',
  'confirmPage.endingIn': 'ending in',
  'confirmPage.startingDate': 'Starting Date',
  'confirmPage.titleCreatePlan': 'Confirm Your Payment Plan',
  'confirmPage.titlePayment': 'Confirm Your Payment',
  'confirmPage.totalBalance': 'Total Balance',
  'confirmPage.viewAllDates': 'View all dates',
  'confirmPage.viewLess': 'View less',

  // Payment Error
  'paymentError.description': 'Please check your payment details and try again',
  'paymentError.title': 'Something went wrong',
  'paymentError.tryAgain': 'Try again',

  // View Confirmation Page
  'viewPaymentConfirmation.createPlan.messageSuccess': 'Congratulations',
  'viewPaymentConfirmation.payment.messageSuccess': 'Payment Submitted!',
  'viewPaymentConfirmation.createPlan.paymentSuccess': 'You created your payment plan successfully',
  'viewPaymentConfirmation.payment.paymentSuccess':
    'Congratulations, your payment is being processed.',
  'viewPaymentConfirmation.transactionId': 'Transaction ID',
  'viewPaymentConfirmation.createPlan.date': 'Starting Date',
  'viewPaymentConfirmation.payInFull.date': 'Date',
  'viewPaymentConfirmation.partialPayment.date': 'Date of payment',
  'viewPaymentConfirmation.originalCreditor': 'Original Creditor',
  'viewPaymentConfirmation.referenceNumber': 'Reference Number',
  'viewPaymentConfirmation.createPlan.payment': 'Payment Plan',
  'viewPaymentConfirmation.partialPayment.payment': 'Partial Payment',
  'viewPaymentConfirmation.payInFull.payment': 'Amount',
  'viewPaymentConfirmation.frequency': 'Frequency',
  'viewPaymentConfirmation.every': 'Every',
  'viewPaymentConfirmation.startingDate': 'Starting Date',
  'viewPaymentConfirmation.paymentMethod': 'Payment Method',
  'viewPaymentConfirmation.endingIn': 'ending in',
  'viewPaymentConfirmation.saveAsPdf': 'Save as PDF',
  'viewPaymentConfirmation.printReceipt': 'Print Receipt',
  'viewPaymentConfirmation.emailReceipt': 'Email Receipt',
  'viewPaymentConfirmation.checkPaymentPlan':
    'You can check your payment plan details in the “payment plan section"',
  'viewPaymentConfirmation.backToHome': 'Back to Home',

  // Not Found
  'notFound.altImage': 'Woman holding Spring Oaks Capital logo',
  'notFound.back': 'Back to home page',
  'notFound.explanation': 'The page you were looking for does not exist',
  'notFound.title': 'Page Not Found',

  // Errors
  'ssn.invalid': 'You must enter a 4 digit SSN number',
  'email.invalid': 'You must enter a valid email',
  'password.presence': 'You must enter a password to continue',
  'passwordConfirmation.presence': 'You must enter a password confirmation to continue',
  'passwordConfirmation.equality': 'Your password confirmation must be equal to the password',
  'account.suppressed':
    'Online access to your account is not available at this time. Please contact us at {contactUsNumber}\n or through the {contactUsLink} page.'
};
