import { useSelector, shallowEqual } from 'react-redux';

const useSelectHolderName = () =>
  useSelector(
    ({ accounts: { details } }) => ({
      firstName: details[0]?.accountholderFirstName,
      lastName: details[0]?.accountholderLastName
    }),
    shallowEqual
  );

export default useSelectHolderName;
