import { useSelector, shallowEqual } from 'react-redux';

const useSelectAccountSuppressed = () =>
  useSelector(
    ({ accounts: { details } }) => ({
      suppressed: details[0]?.suppressed
    }),
    shallowEqual
  );

export default useSelectAccountSuppressed;
