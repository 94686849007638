import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';

import routes from 'constants/routesPaths';
import { BackBanner, Button, FixedFooter, ContentContainer, Loading } from 'components/common';
import PaymentFrequency from 'components/paymentPlans/PaymentFrequency';
import PlanOptions from 'components/paymentPlans/PlanOptions';
import { paymentPlanOptions, selectPlan, savePlanSummary } from 'state/actions/paymentPlanActions';
import { PENDING, FULFILLED } from 'constants/actionStatusConstants';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { getPlanFrequencyInfo } from 'utils/helpers';
import { PAYMENT_FREQUENCY, PLAN_FREQUENCY, REPAYMENT_OPTIONS } from 'constants/constants';
import { md } from 'styles/_variables.scss';
import {
  useDispatch,
  useSelectAccountId,
  useSelectPlanOptions,
  useSelectSelectedPlan,
  useStatus,
  useAnalytics,
  useBreakpoint
} from 'hooks';

const CreatePlanPage = () => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();
  const getOptions = useDispatch(paymentPlanOptions);
  const selectPaymentPlan = useDispatch(selectPlan);
  const setPlanSummary = useDispatch(savePlanSummary);
  const { accountId } = useSelectAccountId();
  const { selectedPlan, selectedRepaymentOption, planSummary } = useSelectSelectedPlan();
  const { weeklyOptions, biweeklyOptions, monthlyOptions } = useSelectPlanOptions();
  const { status } = useStatus(paymentPlanOptions);
  const planOptionsRef = useRef();
  const isDesktop = useBreakpoint(md);

  const [selectedFrequency, setSelectedFrequency] = useState(PAYMENT_FREQUENCY.weekly);
  const [selectedFrequencyLabel, setSelectedFrequencyLabel] = useState(
    intl.formatMessage({ id: PAYMENT_FREQUENCY.weekly })
  );
  const [planOptions, setPlanOptions] = useState([]);

  const onFrequencySelected = (frequency, isPlanSelected = false) => {
    setSelectedFrequency(frequency);

    const { planOption, frequencyId } = getPlanFrequencyInfo(
      frequency,
      weeklyOptions,
      biweeklyOptions,
      monthlyOptions
    );

    if (!isPlanSelected) {
      selectPaymentPlan();
      setPlanSummary();
    }

    setPlanOptions(planOption);
    setSelectedFrequencyLabel(intl.formatMessage({ id: frequencyId }));
    trackEvent(MIXPANEL_EVENTS.frequencySelected, { type: selectedFrequencyLabel });

    return window.scrollTo({ left: 0, top: planOptionsRef.current?.offsetTop, behavior: 'smooth' });
  };

  const onPlanSelected = plan => {
    selectPaymentPlan(plan);
    trackEvent(MIXPANEL_EVENTS.termsSelected, { terms: plan.planTerm });
  };

  const planSummaryTag = useMemo(
    () => <span className="plan-summary text-center">{planSummary}</span>,
    [planSummary]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    status !== FULFILLED && getOptions(accountId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const emptyWeeklyOptions = isEmpty(weeklyOptions);
    setSelectedFrequency(emptyWeeklyOptions ? undefined : PAYMENT_FREQUENCY.weekly);
    setPlanOptions(emptyWeeklyOptions ? [] : weeklyOptions);
  }, [weeklyOptions, biweeklyOptions, monthlyOptions, intl]);

  useEffect(() => {
    if (selectedPlan) {
      const { planTerm, paymentAmount, planScheduleFrequency } = selectedPlan;
      setPlanSummary(
        `${planTerm * planScheduleFrequency} ${selectedFrequencyLabel} payments of
          $${Number(paymentAmount).toFixed(2)}`
      );

      let frequency;

      switch (selectedPlan.planScheduleFrequency) {
        case PLAN_FREQUENCY.weekly:
        default:
          frequency = PAYMENT_FREQUENCY.weekly;
          break;
        case PLAN_FREQUENCY.biweekly:
          frequency = PAYMENT_FREQUENCY.biweekly;
          break;
        case PLAN_FREQUENCY.monthly:
          frequency = PAYMENT_FREQUENCY.monthly;
          break;
      }

      onFrequencySelected(frequency, true);
    }
  }, [selectedPlan]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!selectedRepaymentOption || selectedRepaymentOption !== REPAYMENT_OPTIONS.createPlan) {
    return <Redirect to={routes.repaymentOptions} />;
  }

  return (
    <ContentContainer className="create-plan-container">
      <BackBanner
        ariaLabel={intl.formatMessage({ id: 'createPlan.goBackAria' })}
        title={intl.formatMessage({ id: 'createPlan.goBack' })}
        backTo={routes.repaymentOptions}
      />
      <div className="payment-container">
        <div className="container">
          <h1 className="page-title">{intl.formatMessage({ id: 'createPlan.frequencyTitle' })}</h1>
          {status === PENDING && <Loading />}
          <PaymentFrequency
            showWeeklyOptions={!isEmpty(weeklyOptions)}
            showBiweeklyOptions={!isEmpty(biweeklyOptions)}
            showMonthlyOptions={!isEmpty(monthlyOptions)}
            onFrequencySelected={onFrequencySelected}
            selectedFrequency={selectedFrequency}
          />
          {selectedFrequency && (
            <div ref={planOptionsRef}>
              <PlanOptions
                planOptions={planOptions}
                selectedFrequency={selectedFrequency ? selectedFrequencyLabel : ''}
                selectedPlan={selectedPlan}
                onPlanSelected={onPlanSelected}
              />
            </div>
          )}
          {isDesktop ? (
            <section className="plan-cta">
              {selectedPlan && planSummaryTag}
              <Button
                buttonStyle="primary"
                size="large"
                color="green"
                title={intl.formatMessage({ id: 'createPlan.continue' })}
                ariaLabel={intl.formatMessage({ id: 'createPlan.continueAria' })}
                isDisabled={!selectedPlan}
                linkTo={routes.setupPlanPayment}
              />
            </section>
          ) : (
            selectedPlan && (
              <FixedFooter
                title={intl.formatMessage({ id: 'createPlan.continue' })}
                route={routes.setupPlanPayment}
                ariaLabel={intl.formatMessage({ id: 'createPlan.continueAria' })}
              >
                {planSummaryTag}
              </FixedFooter>
            )
          )}
          {selectedPlan && !isDesktop && (
            <FixedFooter
              title={intl.formatMessage({ id: 'createPlan.continue' })}
              route={routes.setupPlanPayment}
              ariaLabel={intl.formatMessage({ id: 'createPlan.continueAria' })}
            >
              {planSummaryTag}
            </FixedFooter>
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default CreatePlanPage;
