import httpClient from 'httpClient';

class paymentPlanService {
  static repaymentOptions(accountId) {
    return httpClient.post('/repayment-options', { accountId });
  }

  static getPaymentPlans(accountId) {
    return httpClient.post('/payment-plans', { accountId });
  }

  static createPlan(plan) {
    return httpClient.post('/payment-plan', plan);
  }

  static getPlanPaymentDates(planScheduleDetails) {
    return httpClient.post('/payment-schedule', planScheduleDetails);
  }
}

export default paymentPlanService;
