import { createSlice } from '@reduxjs/toolkit';
import { logout, accountsFulfilled } from 'state/actions/userActions';

const initialState = {
  details: {}
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  extraReducers: {
    [accountsFulfilled]: (state, { payload }) => {
      state.details = payload;
    },
    [logout]: () => initialState
  }
});

export default accountsSlice.reducer;
