import { useSelector, shallowEqual } from 'react-redux';

const useSelectPaymentInfo = () =>
  useSelector(
    ({ paymentMethod: { paymentToken, last4, cardType, paymentDetails } }) => ({
      paymentToken,
      last4,
      cardType,
      paymentDetails
    }),
    shallowEqual
  );

export default useSelectPaymentInfo;
