import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import paymentMethodService from 'services/paymentMethodService';
import parseError from 'utils/parseError';

export const paymentForm = createAsyncThunk('paymentForm', async paymentFormInfo => {
  try {
    const {
      data: { data: paymentFormDetails }
    } = await paymentMethodService.paymentForm({ ...paymentFormInfo });
    return paymentFormDetails[0]?.formUrl;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const selectPaymentMethod = createAction('paymentForm/selectPaymentMethod');
export const savePaymentToken = createAction('paymentForm/savePaymentToken');
export const savePaymentLast4 = createAction('paymentForm/savePaymentLast4');
export const savePaymentCardType = createAction('paymentForm/savePaymentCardType');
export const savePaymentDetails = createAction('paymentForm/savePaymentDetails');
export const resetPaymentForm = createAction('paymentForm/resetPaymentForm');

export const { fulfilled: paymentFormFulfilled } = paymentForm;
