import { useSelector, shallowEqual } from 'react-redux';

const useSelectBannerInfo = () =>
  useSelector(
    ({ accounts: { details } }) => ({
      originalCreditor: details[0]?.clientName,
      remainingBalance: details[0]?.remainingBalance,
      referenceNumber: details[0]?.referenceNumber
    }),
    shallowEqual
  );

export default useSelectBannerInfo;
