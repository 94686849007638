import React, { useEffect } from 'react';

import { Pane, ContentContainer, Loading } from 'components/common';
import AccountDetails from 'components/accounts/AccountDetails';
import PaymentCTA from 'components/paymentPlans/PaymentCTA';

import {
  useSelectHolderName,
  useSelectPaymentPlans,
  useSelectAccountId,
  useSelectAccountDetails,
  useDispatch,
  useStatus
} from 'hooks';
import { getPaymentPlans } from 'state/actions/paymentPlanActions';
import { FULFILLED, PENDING } from 'constants/actionStatusConstants';

const HomePage = () => {
  const { firstName } = useSelectHolderName();
  const { accountId } = useSelectAccountId();
  const { hasPaymentPlans } = useSelectPaymentPlans();
  const { remainingBalance } = useSelectAccountDetails();
  const paymentPlansRequest = useDispatch(getPaymentPlans);
  const { status } = useStatus(getPaymentPlans);

  useEffect(() => {
    accountId && paymentPlansRequest(accountId);
  }, [accountId, hasPaymentPlans, paymentPlansRequest]);

  return (
    <ContentContainer>
      <div className="homepage-container">
        <h1 className="home-welcome">{`Welcome ${firstName}!`}</h1>
        <Pane className="mb-4" wide>
          <AccountDetails />
        </Pane>
        {status === FULFILLED && !hasPaymentPlans && remainingBalance > 0 && <PaymentCTA />}
        {status === PENDING && <Loading />}
      </div>
    </ContentContainer>
  );
};

export default HomePage;
