import React, { useEffect, useMemo, useRef } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import cn from 'classnames';
import dayjs from 'dayjs';

import { Arrow, Button, CurrencyText, ContentContainer, Loading, Pane } from 'components/common';
import routes from 'constants/routesPaths';
import PaymentDates from 'components/paymentPlans/PaymentDates';

import { createPaymentPlan, getPlanPaymentDates } from 'state/actions/paymentPlanActions';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { PENDING, FULFILLED, REJECTED } from 'constants/actionStatusConstants';
import { PAYMENT_AUTHORIZATION } from 'constants/disclosures';
import {
  useAnalytics,
  useSelectSelectedPlan,
  useSelectBannerInfo,
  useDispatch,
  useStatus,
  useSelectAccountId,
  useSelectPaymentInfo,
  useSelectPaymentMethod,
  useSelectPaymentDates
} from 'hooks';
import {
  REPAYMENT_OPTIONS_LABEL,
  REPAYMENT_OPTIONS,
  ACCOUNT_DATE_FORMAT,
  PLAN_FREQUENCY,
  PAYMENT_METHOD_OPTIONS
} from 'constants/constants';

import BankIcon from 'assets/icons/bankIcon.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import PaymentPlanIcon from 'assets/icons/paymentPlan.svg';
import PayInFullIcon from 'assets/icons/payInFull.svg';
import PartialPaymentIcon from 'assets/icons/partialPayment.svg';

const messages = defineMessages({
  back: { id: 'createPlan.goBack' },
  backAria: { id: 'createPlan.goBackAria' },
  bankAccount: { id: 'paymentMethod.bank' },
  creditor: { id: 'accounts.originalCreditor' },
  endingIn: { id: 'confirmPage.endingIn' },
  every: { id: 'confirmPage.every' },
  ofTheMonth: { id: 'paymentFrequency.ofTheMonth' },
  frequency: { id: 'confirmPage.frequency' },
  paymentMethod: { id: 'confirmPage.paymentMethod' },
  reference: { id: 'accounts.referenceNumber' },
  titleCreatePlan: { id: 'confirmPage.titleCreatePlan' },
  titlePayment: { id: 'confirmPage.titlePayment' },
  totalBalance: { id: 'confirmPage.totalBalance' }
});

const ConfirmPaymentPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const didMount = useRef(false);

  const createPlanRequest = useDispatch(createPaymentPlan);
  const paymentDatesRequest = useDispatch(getPlanPaymentDates);
  const resetCreatePlan = useDispatch(createPaymentPlan.reset);

  const { status } = useStatus(createPaymentPlan);
  const { status: datesStatus } = useStatus(getPlanPaymentDates);

  const { originalCreditor, referenceNumber, remainingBalance } = useSelectBannerInfo();
  const { accountId } = useSelectAccountId();
  const { selectedPaymentMethod } = useSelectPaymentMethod();
  const { paymentToken, last4, cardType, paymentDetails } = useSelectPaymentInfo();
  const { paymentSchedule } = useSelectPaymentDates();
  const {
    selectedRepaymentOption,
    planSummary,
    selectedDate,
    selectedFrequency,
    selectedPlan,
    selectedAmount
  } = useSelectSelectedPlan();

  const dateLabel = useMemo(
    () =>
      selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan
        ? intl.formatMessage({ id: 'confirmPage.startingDate' })
        : intl.formatMessage({ id: 'confirmPage.paymentDate' }),
    [selectedRepaymentOption, intl]
  );

  const buttonLabel = intl.formatMessage({ id: 'confirmPage.authorize' });

  const formattedDate = useMemo(() => dayjs(selectedDate).format(ACCOUNT_DATE_FORMAT), [
    selectedDate
  ]);

  const titleMessage = useMemo(
    () =>
      selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan
        ? intl.formatMessage(messages.titleCreatePlan)
        : intl.formatMessage(messages.titlePayment),
    [selectedRepaymentOption, intl]
  );

  const planIcon = useMemo(() => {
    switch (selectedRepaymentOption) {
      case REPAYMENT_OPTIONS.createPlan:
        return <PaymentPlanIcon />;
      case REPAYMENT_OPTIONS.partialPayment:
        return <PartialPaymentIcon />;
      case REPAYMENT_OPTIONS.payInFull:
      default:
        return <PayInFullIcon />;
    }
  }, [selectedRepaymentOption]);

  const isPlanSelected = useMemo(() => !!selectedRepaymentOption, [selectedRepaymentOption]);

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.viewConfirmPaymentPage, { option: selectedRepaymentOption });
    resetCreatePlan();
    selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan &&
      paymentDatesRequest({
        accountId,
        paymentToken,
        selectedPaymentMethod,
        selectedRepaymentOption,
        selectedDate,
        selectedPlan,
        selectedFrequency,
        paymentAmount: null
      });

    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (didMount.current) {
      status === REJECTED && history.push(routes.paymentErrorPage);
      status === FULFILLED && history.push(routes.paymentPlanSuccess);
    } else {
      didMount.current = true;
    }
  }, [status, history]);

  const onPlanCreateClick = () => {
    trackEvent(
      selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan
        ? MIXPANEL_EVENTS.clickPaymentPlan
        : MIXPANEL_EVENTS.clickPay
    );
    createPlanRequest({
      accountId,
      paymentToken,
      paymentDetails,
      selectedPaymentMethod,
      selectedRepaymentOption,
      selectedDate,
      selectedPlan,
      selectedFrequency,
      paymentAmount:
        selectedRepaymentOption === REPAYMENT_OPTIONS.partialPayment ? selectedAmount : null
    });
  };

  if (!isPlanSelected) {
    return <Redirect to={routes.repaymentOptions} />;
  }

  return (
    <ContentContainer>
      <div className="container">
        <Button
          className="back-home-link my-4 my-md-5"
          ariaLabel={intl.formatMessage(messages.backAria)}
          title={intl.formatMessage(messages.back)}
          onClick={history.goBack}
        >
          <Arrow type="left" className="mr-3" />
          {intl.formatMessage(messages.back)}
        </Button>

        <h1 className="page-title">{titleMessage}</h1>

        <Pane className="confirm-payment-pane" wide>
          <span className="attribute-title">
            {`${intl.formatMessage(messages.creditor)}: `}
            <span className="attribute-value">{originalCreditor}</span>
          </span>
          <span className="attribute-title">
            {`${intl.formatMessage(messages.reference)}: `}
            <span className="attribute-value">{referenceNumber}</span>
          </span>
          <span className="attribute-title">
            {planIcon}
            {intl.formatMessage({ id: REPAYMENT_OPTIONS_LABEL[selectedRepaymentOption] })}
            {selectedRepaymentOption !== REPAYMENT_OPTIONS.payInFull && (
              <>
                {`: `}
                <span className="attribute-value">{planSummary}</span>
              </>
            )}
          </span>
          {selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan && (
            <>
              {datesStatus === PENDING && <Loading />}
              <PaymentDates paymentSchedule={paymentSchedule} />
            </>
          )}
          {selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan && (
            <span className="attribute-title">
              {`${intl.formatMessage(messages.frequency)}: `}
              <span className="attribute-value">
                {intl.formatMessage(messages.every)} {selectedFrequency.value}
                {selectedPlan.planScheduleFrequency === PLAN_FREQUENCY.monthly && (
                  <>{intl.formatMessage({ id: 'paymentFrequency.ofTheMonth' })}</>
                )}
              </span>
            </span>
          )}
          {selectedRepaymentOption !== REPAYMENT_OPTIONS.createPlan && (
            <span className="attribute-title">
              <CalendarIcon />
              {`${dateLabel}: `}
              <span className="attribute-value">{formattedDate}</span>
            </span>
          )}
          <span className="attribute-title d-md-flex align-items-center">
            {`${intl.formatMessage(messages.paymentMethod)}: `}
            <div
              className={cn('d-md-flex ml-md-2', {
                'd-inline': PAYMENT_METHOD_OPTIONS.card === selectedPaymentMethod
              })}
            >
              {PAYMENT_METHOD_OPTIONS.card === selectedPaymentMethod ? (
                <span className="attribute-value card-type">
                  <p>{cardType}</p>
                </span>
              ) : (
                <span className="attribute-value d-inline-flex align-items-center">
                  <BankIcon />
                  {intl.formatMessage(messages.bankAccount)}
                </span>
              )}
              <span className="font-weight-bold mx-2 d-md-flex align-items-center">
                {intl.formatMessage(messages.endingIn)}
              </span>
              <span className="attribute-value d-inline-flex align-items-center">{last4}</span>
            </div>
          </span>

          {selectedRepaymentOption === REPAYMENT_OPTIONS.payInFull && (
            <span className="attribute-title">
              {`${intl.formatMessage(messages.totalBalance)}: `}
              <span className="attribute-value">
                <CurrencyText value={remainingBalance} />
              </span>
            </span>
          )}
        </Pane>

        <section className="plan-cta align-items-start">
          <span className="payment-authorization">
            {PAYMENT_METHOD_OPTIONS.card === selectedPaymentMethod
              ? PAYMENT_AUTHORIZATION.card
              : PAYMENT_AUTHORIZATION.ACH}
            {PAYMENT_AUTHORIZATION.cta.replace('{buttonText}', buttonLabel)}
            {PAYMENT_AUTHORIZATION.print}
          </span>

          {status === PENDING ? (
            <Loading />
          ) : (
            <Button
              buttonStyle="primary"
              size="large"
              color="green"
              title={buttonLabel}
              ariaLabel={buttonLabel}
              onClick={onPlanCreateClick}
            />
          )}
        </section>
      </div>
    </ContentContainer>
  );
};

export default ConfirmPaymentPage;
