import { updateSession, logout } from 'state/actions/userActions';

const REQUEST_ACCESS_TOKEN = 'Authorization';
const RESPONSE_ACCESS_TOKEN = 'authToken';
const X_API_KEY = 'x-api-key';
const { API_KEY } = process.env;

const FORBIDDEN = 403;

export default (store, client) => {
  client.interceptors.request.use(config => {
    const { info } = store.getState().session;
    const { headers } = config;
    config.headers = {
      ...headers,
      [X_API_KEY]: API_KEY
    };
    const { token } = info;
    if (token) {
      config.headers = {
        ...config.headers,
        [REQUEST_ACCESS_TOKEN]: `Bearer ${token}`
      };
    }
    return config;
  });

  client.interceptors.response.use(
    async response => {
      const { data } = response;
      const token = data[RESPONSE_ACCESS_TOKEN];
      if (token) {
        const tokenExpire =
          JSON.parse(atob(token.split('.')[1])).expires * 1000 - Math.floor(Date.now());
        const session = { token, tokenExpire };
        store.dispatch(updateSession(session));
      }
      return response;
    },
    error => {
      if (error.response && error.response.status === FORBIDDEN) {
        alert('Session expired');
        store.dispatch(logout());
      }

      return Promise.reject(error);
    }
  );
};
