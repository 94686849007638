import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { string, func, oneOf, node, bool } from 'prop-types';
import cn from 'classnames';

export const buttonSize = {
  XSMALL: 'x-small',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

export const buttonColor = {
  GREEN: 'green',
  BLACK: 'black',
  TRANSPARENT: 'transparent',
  WHITE: 'white'
};

export const buttonStyle = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

const Button = ({
  buttonStyle,
  size,
  title,
  children,
  linkTo,
  className,
  ariaLabel,
  ariaExpanded,
  color = buttonColor.BLACK,
  type = 'button',
  wide = false,
  isDisabled = false,
  onClick = () => {}
}) => {
  const classnames = cn(
    'button',
    `button--${buttonStyle}`,
    `button--${size}`,
    `button--${color}`,
    {
      'button--wide': wide
    },
    {
      'button--disabled': isDisabled
    },
    className
  );

  return (
    <>
      {linkTo ? (
        <Link to={linkTo} className={classnames} onClick={onClick} aria-label={ariaLabel}>
          {children || title}
        </Link>
      ) : (
        // eslint-disable-next-line react/button-has-type
        <button
          type={type}
          className={classnames}
          onClick={onClick}
          aria-label={ariaLabel}
          aria-expanded={ariaExpanded}
          disabled={isDisabled}
        >
          {children || title}
        </button>
      )}
    </>
  );
};

Button.propTypes = {
  title: string,
  onClick: func,
  buttonStyle: oneOf([buttonStyle.PRIMARY, buttonStyle.SECONDARY]),
  type: string,
  size: oneOf([buttonSize.XSMALL, buttonSize.SMALL, buttonSize.MEDIUM, buttonSize.LARGE]),
  color: oneOf([buttonColor.GREEN, buttonColor.BLACK, buttonColor.TRANSPARENT, buttonColor.WHITE]),
  children: node,
  wide: bool,
  linkTo: string,
  className: string,
  ariaLabel: string,
  ariaExpanded: string,
  isDisabled: bool
};

export default memo(Button);
