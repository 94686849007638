import React from 'react';
import { useIntl } from 'react-intl';

import { Pane } from 'components/common';
import Button, { buttonColor, buttonStyle, buttonSize } from 'components/common/Button';
import routes from 'constants/routesPaths';
import { useAnalytics } from 'hooks';
import { MIXPANEL_EVENTS, MIXPANEL_LOCATIONS } from 'constants/mixpanelEvents';

const PaymentCTABlock = () => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();

  const trackEvents = () =>
    trackEvent(MIXPANEL_EVENTS.clickMorePaymentOptions, {
      Location: MIXPANEL_LOCATIONS.accountDetails
    });

  return (
    <Pane className="payment-options-block" wide>
      <div>
        <h2>{intl.formatMessage({ id: 'paymentOptionsBlock.title' })}</h2>
        <span>{intl.formatMessage({ id: 'paymentOptionsBlock.desc' })}</span>
      </div>

      <Button
        linkTo={routes.repaymentOptions}
        buttonStyle={buttonStyle.PRIMARY}
        size={buttonSize.SMALL}
        color={buttonColor.BLACK}
        onClick={trackEvents}
      >
        {intl.formatMessage({ id: 'paymentOptionsBlock.cta' })}
      </Button>
    </Pane>
  );
};

export default PaymentCTABlock;
