import React from 'react';
import { string, func, node } from 'prop-types';

import { Button } from 'components/common';

const FixedFooter = ({ title, route, children, ariaLabel = title, onClick = () => {} }) => (
  <section className="fixed-footer">
    {children ?? null}
    <Button
      linkTo={route}
      buttonStyle="primary"
      size="large"
      color="green"
      title={title}
      ariaLabel={ariaLabel}
      onClick={onClick}
      wide
    />
  </section>
);

FixedFooter.propTypes = {
  title: string.isRequired,
  route: string.isRequired,
  ariaLabel: string,
  onClick: func,
  children: node
};

export default FixedFooter;
