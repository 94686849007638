import React from 'react';

import AccountErrorPage from 'pages/AccountErrorPage';
import ConfirmPaymentPage from 'pages/ConfirmPaymentPage';
import CreatePlanPage from 'pages/CreatePlanPage';
import DisclosuresPage from 'pages/DisclosuresPage';
import DocumentsPage from 'pages/DocumentsPage';
import HomePage from 'pages/HomePage';
import LoginPage from 'pages/LoginPage';
import NotFoundPage from 'pages/NotFoundPage';
import PartialPaymentPage from 'pages/PartialPaymentPage';
import PaymentErrorPage from 'pages/PaymentErrorPage';
import PaymentPlanSuccess from 'pages/PaymentPlanSuccess';
import RepaymentOptionPage from 'pages/RepaymentOptionPage';
import routesPaths from 'constants/routesPaths';
import SetupPaymentPage from 'pages/SetupPaymentPage';

const routes = [
  {
    path: routesPaths.index,
    component: <HomePage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.createPaymentPlan,
    component: <CreatePlanPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.repaymentOptions,
    component: <RepaymentOptionPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.partialPayment,
    component: <PartialPaymentPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.setupPlanPayment,
    component: <SetupPaymentPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.confirmPaymentPage,
    component: <ConfirmPaymentPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.paymentPlanSuccess,
    component: <PaymentPlanSuccess />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.paymentErrorPage,
    component: <PaymentErrorPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.login,
    component: <LoginPage />
  },
  {
    path: routesPaths.disclosures,
    component: <DisclosuresPage />,
    exact: true
  },
  {
    path: routesPaths.homeDisclosures,
    component: <DisclosuresPage />,
    private: true,
    exact: true
  },
  {
    path: routesPaths.documents,
    component: <DocumentsPage />,
    private: true,
    exact: true
  },
  {
    path: routesPaths.accountError,
    component: <AccountErrorPage />,
    exact: true
  },
  {
    component: <NotFoundPage />
  }
];

export default routes;
