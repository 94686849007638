import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import cn from 'classnames';

import BankIcon from 'assets/icons/bankIcon.svg';
import CardIcon from 'assets/icons/cardIcon.svg';
import { BackBanner, Select, TileButton, DatePickerInput } from 'components/common';
import routes from 'constants/routesPaths';
import { green } from 'styles/_variables.scss';
import {
  DATEPICKER_DATE_FORMAT,
  IFRAME_LOADER_FORMAT,
  IFRAME_LOADER_SIZE,
  DAYS_WEEK,
  PLAN_FREQUENCY,
  REPAYMENT_OPTIONS,
  PAYMENT_STATUS,
  PAYMENT_METHOD_OPTIONS,
  getSelectDays
} from 'constants/constants';
import { selectDate, selectFrequency, paymentPlanOptions } from 'state/actions/paymentPlanActions';
import {
  paymentForm,
  resetPaymentForm,
  selectPaymentMethod,
  savePaymentToken,
  savePaymentCardType,
  savePaymentLast4,
  savePaymentDetails
} from 'state/actions/paymentMethodActions';
import {
  useSelectPlanOptions,
  useSelectSelectedPlan,
  useSelectAccountId,
  useDispatch,
  useSelectPaymentForm,
  useSelectPaymentMethod,
  useSelectPaymentInfo,
  useAnalytics,
  useStatus
} from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { FULFILLED } from 'constants/actionStatusConstants';

const weekDays = Object.entries(DAYS_WEEK);
const daysOptions = getSelectDays();
const repayUrls = process.env.REPAY_URLS.split(',');

const SetupPaymentPage = () => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();
  const { accountId } = useSelectAccountId();
  const setDate = useDispatch(selectDate);
  const setFrequency = useDispatch(selectFrequency);
  const getPlanOptions = useDispatch(paymentPlanOptions);
  const { optionStatus } = useStatus(paymentPlanOptions);
  const paymentFormRequest = useDispatch(paymentForm);
  const selectPaymentMethodRequest = useDispatch(selectPaymentMethod);
  const resetPaymentFormRequest = useDispatch(resetPaymentForm);
  const savePaymentTokenRequest = useDispatch(savePaymentToken);
  const savePaymentCardTypeRequest = useDispatch(savePaymentCardType);
  const savePaymentLast4Request = useDispatch(savePaymentLast4);
  const savePaymentDetailsRequest = useDispatch(savePaymentDetails);

  const { allowedPlanStartLatency } = useSelectPlanOptions();
  const paymentLoaderRef = useRef();
  const [daysDisclaimer, setDaysDisclaimer] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);

  const {
    selectedDate,
    selectedPlan,
    selectedFrequency,
    selectedRepaymentOption
  } = useSelectSelectedPlan();

  const { paymentFormUrl, hasPaymentForm } = useSelectPaymentForm();
  const { selectedPaymentMethod } = useSelectPaymentMethod();
  const { paymentToken } = useSelectPaymentInfo();
  const isCreatePlan = selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan;

  const paymentHandler = e => {
    if (!repayUrls.includes(e.origin)) {
      return;
    }
    if (e.data === PAYMENT_STATUS.success) {
      setPaymentSuccess(true);
    }
    if (e.data?.paymentResponseData) {
      const {
        saved_payment_method: { token },
        payment_type_id: savedCardType,
        customer_id: customerId,
        pn_ref: pnRef,
        result_details: { card_info: { type = '' } = {} } = {},
        result,
        last4,
        date
      } = e.data.paymentResponseData;
      savePaymentCardTypeRequest(savedCardType);
      savePaymentLast4Request(last4);
      savePaymentTokenRequest(token);
      savePaymentDetailsRequest({
        date,
        customer_id: customerId,
        pn_ref: pnRef,
        card_type: type,
        result,
        last4
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    trackEvent(MIXPANEL_EVENTS.viewSetupPaymentPage);
    resetPaymentFormRequest();
    window.addEventListener('message', paymentHandler);
    optionStatus !== FULFILLED && getPlanOptions(accountId);
    return () => window.removeEventListener('message', paymentHandler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDaysDisclaimer(
      intl.formatMessage({ id: 'setupPayment.daysDisclaimer' }, { days: allowedPlanStartLatency })
    );
  }, [allowedPlanStartLatency, intl]);

  useEffect(() => {
    if (selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan && selectedPlan) {
      const { planScheduleFrequency } = selectedPlan;
      let options = [];

      switch (planScheduleFrequency) {
        case PLAN_FREQUENCY.monthly:
          options = daysOptions.map((day, index) => ({
            key: index + 1,
            value: day
          }));
          break;
        case PLAN_FREQUENCY.weekly:
        case PLAN_FREQUENCY.biweekly:
        default:
          options = weekDays.map(([key, value]) => ({
            key,
            value: intl.formatMessage({ id: value })
          }));
          break;
      }

      setSelectOptions(options);
      options.length > 1 && setFrequency(options[0]);
    }
  }, [selectedPlan, selectedRepaymentOption, intl, setFrequency]);

  const onFrequencySelect = frequency => {
    setFrequency(frequency);
    trackEvent(MIXPANEL_EVENTS.selectPaymentFrequency, {
      frequency
    });
  };

  const onDateSelect = () => {
    trackEvent(MIXPANEL_EVENTS.startDateSelected, {
      latency: dayjs(selectedDate)
        .add(2, 'hour')
        .diff(dayjs(), 'day')
    });
  };

  const onPaymentMethodSelect = paymentMethod => {
    setLoaderVisible(!loaderVisible);
    resetPaymentFormRequest();
    selectPaymentMethodRequest(paymentMethod);
    trackEvent(MIXPANEL_EVENTS.selectPaymentMethod, { method: paymentMethod });
    paymentFormRequest({ accountId, methodType: paymentMethod });
    return paymentLoaderRef.current?.scrollIntoView();
  };

  const backURL = useMemo(() => {
    let route;

    switch (selectedRepaymentOption) {
      case REPAYMENT_OPTIONS.createPlan:
        route = routes.createPaymentPlan;
        break;
      case REPAYMENT_OPTIONS.partialPayment:
        route = routes.partialPayment;
        break;
      case REPAYMENT_OPTIONS.payInFull:
      default:
        route = routes.repaymentOptions;
        break;
    }

    return route;
  }, [selectedRepaymentOption, routes]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!selectedRepaymentOption) {
    return <Redirect to={routes.repaymentOptions} />;
  }

  if (paymentSuccess && paymentToken) {
    return <Redirect push to={routes.confirmPaymentPage} />;
  }

  return (
    <div className="setup-payment-page-container">
      <BackBanner
        ariaLabel={intl.formatMessage({ id: 'createPlan.goBackAria' })}
        title={intl.formatMessage({ id: 'createPlan.goBack' })}
        backTo={backURL}
      />
      <div className="payment-container">
        <div className="container">
          <h1 className="page-title">{intl.formatMessage({ id: 'setupPayment.title' })}</h1>
          {!isCreatePlan && (
            <h2 className="page-subtitle">
              {intl.formatMessage({ id: 'setupPayment.selectDate' })}
            </h2>
          )}
          <div className="row">
            {!isCreatePlan && (
              <>
                <div className="col-md-4">
                  <DatePickerInput
                    format={DATEPICKER_DATE_FORMAT}
                    label={intl.formatMessage({ id: 'setupPayment.selectDateLabel' })}
                    placeholder=""
                    minDate={new Date()}
                    maxDate={dayjs()
                      .add(allowedPlanStartLatency, 'day')
                      .toDate()}
                    monthsShown={1}
                    value={selectedDate}
                    onSelectDate={date => setDate(date)}
                    showCalendarIcon
                    onCalendarClose={onDateSelect}
                    error={!selectedDate && 'setupPayment.selectDateError'}
                  />
                </div>
                <p className="col-12">{daysDisclaimer}</p>
              </>
            )}
            {selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan && (
              <div className="d-flex align-items-baseline col-md-8">
                <span className="page-subtitle mb-0">
                  {intl.formatMessage({ id: 'setupPayment.selectFrequency' })}
                </span>
                <span className="ml-4">{intl.formatMessage({ id: 'setupPayment.every' })}</span>
                <Select
                  selectedFrequency={selectedFrequency}
                  className="ml-2"
                  options={selectOptions}
                  onOptionSelected={onFrequencySelect}
                />
                {selectedPlan.planScheduleFrequency === PLAN_FREQUENCY.monthly && (
                  <span className="ml-2">
                    {intl.formatMessage({ id: 'paymentFrequency.ofTheMonth' })}
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="tile-button-container mb-0 justify-content-center" ref={paymentLoaderRef}>
            <TileButton
              label={intl.formatMessage({ id: 'paymentMethod.card' })}
              ariaLabel={intl.formatMessage({ id: 'paymentMethod.card' })}
              active={PAYMENT_METHOD_OPTIONS.card === selectedPaymentMethod}
              onClick={() => onPaymentMethodSelect(PAYMENT_METHOD_OPTIONS.card)}
              icon={<CardIcon />}
            />
            <TileButton
              label={intl.formatMessage({ id: 'paymentMethod.bank' })}
              ariaLabel={intl.formatMessage({ id: 'paymentMethod.bank' })}
              active={PAYMENT_METHOD_OPTIONS.bank === selectedPaymentMethod}
              onClick={() => onPaymentMethodSelect(PAYMENT_METHOD_OPTIONS.bank)}
              icon={<BankIcon />}
            />
          </div>
        </div>
      </div>
      <div className="text-center">
        <Loader
          visible={loaderVisible}
          type={IFRAME_LOADER_FORMAT}
          color={green}
          height={IFRAME_LOADER_SIZE}
          width={IFRAME_LOADER_SIZE}
        />
      </div>
      <div
        className={cn(
          'payment-method-container',
          { 'payment-method-card': PAYMENT_METHOD_OPTIONS.card === selectedPaymentMethod },
          { 'payment-method-bank': PAYMENT_METHOD_OPTIONS.bank === selectedPaymentMethod }
        )}
      >
        {hasPaymentForm && (
          <iframe
            title="Repay Payment Method"
            key={paymentFormUrl}
            src={paymentFormUrl}
            onLoad={() => setLoaderVisible(false)}
            scrolling="no"
            className="h-100 w-100 pt-3"
          />
        )}
      </div>
    </div>
  );
};

export default SetupPaymentPage;
