import { createAction } from '@reduxjs/toolkit';
import mixpanel from 'mixpanel-browser';

import userService from 'services/userService';
import parseError from 'utils/parseError';
import createAsyncThunk from 'utils/createAsyncThunk';
import { toBase64 } from 'utils/helpers';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';

export const login = createAsyncThunk('user/login', async user => {
  try {
    mixpanel.track(MIXPANEL_EVENTS.loginAttempt);
    const {
      data: { data: userInfo }
    } = await userService.login({ ...user });
    mixpanel.track(MIXPANEL_EVENTS.loginSuccess);
    mixpanel.identify(userInfo?.accountIds[0]);

    return userInfo;
  } catch ({ response: { data } }) {
    mixpanel.track(MIXPANEL_EVENTS.loginFail);
    throw parseError(data);
  }
});

export const accounts = createAsyncThunk('user/accounts', async accountIds => {
  try {
    const {
      data: { data: accountDetails }
    } = await userService.accounts(accountIds);

    return accountDetails;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const documents = createAsyncThunk('user/documents', async accountId => {
  try {
    const {
      data: {
        data: { documents: documentsInfo }
      }
    } = await userService.documents(accountId);
    return documentsInfo.map(doc => ({ ...doc, documentBase64: '' }));
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const document = createAsyncThunk('user/document', async documentInfo => {
  try {
    const {
      data: {
        data: { documentBase64 }
      }
    } = await userService.document({ ...documentInfo });
    return { document: toBase64(documentBase64), documentId: documentInfo.documentId };
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const resetDocuments = createAction('user/resetDocuments');

export const logout = createAction('user/logout', () => {
  mixpanel.reset();
  return {
    payload: {}
  };
});

export const toggleBackBannerSummary = createAction('user/toggleBackBannerSummary');

export const updateSession = createAction('session/update');

export const { fulfilled: accountsFulfilled } = accounts;

export const { fulfilled: documentsFulfilled } = documents;

export const { fulfilled: documentFulfilled } = document;

export const { fulfilled: loginFulfilled, rejected: loginRejected } = login;
