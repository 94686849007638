import { useSelector, shallowEqual } from 'react-redux';

const useSelectDocuments = () =>
  useSelector(
    ({ documents: { documents } }) => ({
      documents
    }),
    shallowEqual
  );

export default useSelectDocuments;
