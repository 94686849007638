import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactModal from 'react-modal';

import CloseIcon from 'assets/icons/closeIcon.svg';
import { Button, Header, SideMenu } from 'components/common';
import useToggle from 'hooks/useToggle';
import { useSession, useSelectAccountSuppressed, useAnalytics } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';

const withLayout = WrappedComponent => {
  const Layout = props => {
    const location = useLocation();
    const { trackEvent } = useAnalytics();
    const [isMenuOpen, toggleMenuOpen] = useToggle();
    const { authenticated } = useSession();
    const { suppressed } = useSelectAccountSuppressed();
    const isValidUser = authenticated && suppressed === false;
    const showMenu = isValidUser && !location.state?.hideMenu;

    useEffect(() => {
      isMenuOpen && trackEvent(MIXPANEL_EVENTS.openSideMenu);
      isMenuOpen === false && trackEvent(MIXPANEL_EVENTS.closeSideMenu);
    }, [isMenuOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Header showMenu={showMenu} onMenuClick={toggleMenuOpen} />
        <main role="main" className="layout-content">
          {showMenu && <SideMenu className="d-none d-md-block" />}
          <WrappedComponent {...props} />
        </main>
        <ReactModal
          isOpen={isMenuOpen}
          onRequestClose={toggleMenuOpen}
          overlayClassName="sidemenu-overlay"
          className="sidemenu-content"
        >
          <Button
            ariaLabel="Close Menu"
            className="button--icon sidemenu-close"
            onClick={toggleMenuOpen}
          >
            <CloseIcon />
          </Button>
          <SideMenu />
        </ReactModal>
      </>
    );
  };

  return Layout;
};

export default withLayout;
