// App common constants
import { getOrdinal } from 'utils/helpers';

export const SUPPORTED_LANGUAGES = ['en'];
export const DEFAULT_LANGUAGE = 'en';
export const DATEPICKER_DATE_FORMAT = 'MM/dd/yyyy';
export const DATEINPUT_FORMAT = '##/##/####';
export const DATEINPUT_MASK = ['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y'];
export const ACCOUNT_DATE_FORMAT = 'MMMM D, YYYY';
export const PAYLOAD_DATE_FORMAT = 'YYYY-MM-DD';
export const SOC_WEB_URL = 'https://springoakscapital.com/';
export const IFRAME_LOADER_FORMAT = 'ThreeDots';
export const IFRAME_LOADER_SIZE = 50;

export const PAYMENT_FREQUENCY = {
  weekly: 'paymentFrequency.weekly',
  biweekly: 'paymentFrequency.biweekly',
  monthly: 'paymentFrequency.monthly'
};

export const DAYS_WEEK = {
  monday: 'days.monday',
  tuesday: 'days.tuesday',
  wednesday: 'days.wednesday',
  thursday: 'days.thursday',
  friday: 'days.friday',
  saturday: 'days.saturday',
  sunday: 'days.sunday'
};

export const PLAN_FREQUENCY = {
  weekly: 4,
  biweekly: 2,
  monthly: 1
};

export const getSelectDays = () => {
  const daysArray = [...Array(28).keys()];
  const daysOptions = [];
  daysArray.map(day => daysOptions.push(getOrdinal(day + 1)));
  daysOptions.push('Last day of the month');

  return daysOptions;
};

export const REPAYMENT_OPTIONS = {
  createPlan: 'schedule',
  partialPayment: 'partial',
  payInFull: 'PIF'
};

export const REPAYMENT_OPTIONS_LABEL = {
  [REPAYMENT_OPTIONS.createPlan]: 'planType.paymentPlan',
  [REPAYMENT_OPTIONS.partialPayment]: 'planType.partialPayment',
  [REPAYMENT_OPTIONS.payInFull]: 'planType.payInFull'
};

export const CONFIRMATION_OPTIONS_ID = {
  [REPAYMENT_OPTIONS.createPlan]: 'viewPaymentConfirmation.createPlan',
  [REPAYMENT_OPTIONS.partialPayment]: 'viewPaymentConfirmation.partialPayment',
  [REPAYMENT_OPTIONS.payInFull]: 'viewPaymentConfirmation.payInFull'
};

export const PARTIAL_PAYMENTS_OPTIONS = [10, 25, 50, 75, 100];

export const PAYMENT_STATUS = {
  success: 'PAYMENT_SUCCESS',
  error: 'PAYMENT_ERROR'
};

export const PAYMENT_METHOD_OPTIONS = {
  card: 'card',
  bank: 'ach'
};

export const DEFAULT_PLAN = {
  planTerm: 1,
  planScheduleFrequency: 1
};
