import { createSlice } from '@reduxjs/toolkit';
import {
  paymentOptionsFulfilled,
  selectRepaymentOption,
  selectPlan,
  selectDate,
  selectFrequency,
  getPaymentPlansFulfilled,
  selectAmount,
  savePlanSummary,
  resetRepaymentOption,
  createPaymentPlanFulfilled,
  getPlanPaymentDatesFulfilled
} from 'state/actions/paymentPlanActions';
import { logout } from 'state/actions/userActions';

const initialState = {
  accountPaymentPlan: [],
  selectedRepaymentOption: undefined,
  selectedPlan: undefined,
  selectedDate: new Date(),
  selectedFrequency: {},
  selectedAmount: null,
  paymentPlans: [],
  planSummary: '',
  newPlan: undefined,
  paymentSchedule: []
};

const paymentPlanSlice = createSlice({
  name: 'paymentPlan',
  initialState,
  extraReducers: {
    [paymentOptionsFulfilled]: (state, { payload }) => {
      state.accountPaymentPlan = payload;
    },
    [selectRepaymentOption]: (state, { payload }) => {
      state.selectedRepaymentOption = payload;
      state.selectedPlan = initialState.selectedPlan;
      state.selectedDate = initialState.selectedDate;
      state.selectedFrequency = initialState.selectedFrequency;
      state.selectedAmount = initialState.selectedAmount;
      state.planSummary = initialState.planSummary;
    },
    [selectPlan]: (state, { payload }) => {
      state.selectedPlan = payload;
    },
    [selectDate]: (state, { payload }) => {
      state.selectedDate = payload;
    },
    [selectFrequency]: (state, { payload }) => {
      state.selectedFrequency = payload;
    },
    [selectAmount]: (state, { payload }) => {
      state.selectedAmount = payload;
    },
    [getPaymentPlansFulfilled]: (state, { payload }) => {
      state.paymentPlans = payload;
    },
    [savePlanSummary]: (state, { payload }) => {
      state.planSummary = payload;
    },
    [createPaymentPlanFulfilled]: (state, { payload }) => {
      state.newPlan = payload;
    },
    [getPlanPaymentDatesFulfilled]: (state, { payload }) => {
      state.paymentSchedule = payload;
    },
    [resetRepaymentOption]: () => initialState,
    [logout]: () => initialState
  }
});

export default paymentPlanSlice.reducer;
