import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { func, bool } from 'prop-types';

import { Button } from 'components/common';
import HamburgerIcon from 'assets/icons/hamburgerIcon.svg';
import LogoIcon from 'assets/icons/logoIcon.svg';
import { useAnalytics } from 'hooks';
import routesPaths from 'constants/routesPaths';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { mixPanelLocation } from 'utils/mixPanelLocation';

const Header = ({ onMenuClick = () => {}, showMenu = false }) => {
  const location = useLocation();
  const { trackEvent } = useAnalytics();

  return (
    <header className="header">
      <div className="px-0">
        <div className="row no-gutters justify-content-start align-items-center">
          {showMenu && (
            <Button
              className="button--icon mr-1 mr-sm-3 d-block d-sm-block d-md-none"
              ariaLabel="Open Menu"
              onClick={onMenuClick}
            >
              <HamburgerIcon />
            </Button>
          )}
          <Link
            className="soc-logo"
            aria-label="Go to Home Page"
            to={routesPaths.index}
            onClick={() =>
              trackEvent(MIXPANEL_EVENTS.clickHeaderLogo, mixPanelLocation(location.pathname))
            }
          >
            <LogoIcon />
          </Link>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  onMenuClick: func,
  showMenu: bool
};

export default Header;
