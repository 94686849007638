import { useSelector, shallowEqual } from 'react-redux';

const useSelectAccountId = () =>
  useSelector(
    ({ session: { user } }) => ({
      accountId: user?.accountIds[0]
    }),
    shallowEqual
  );

export default useSelectAccountId;
