import React from 'react';
import { useIntl } from 'react-intl';

import { useSelectAccountDetails } from 'hooks';
import { DISCLOSURES, STATUTE_OF_LIMITATIONS } from 'constants/disclosures';

const Disclosures = () => {
  const intl = useIntl();
  const { solDisclosureId } = useSelectAccountDetails();

  return (
    <section className="disclosure pt-5">
      <h1 className="my-3 my-lg-5">{intl.formatMessage({ id: 'disclosures.title' })}</h1>
      <hr className="mb-4 mb-lg-5" />
      {solDisclosureId && (
        <div className="mb-lg-5">
          <p>{STATUTE_OF_LIMITATIONS[solDisclosureId - 1]}</p>
        </div>
      )}
      {DISCLOSURES.map(({ title, subtitle, paragraph }, index) => (
        <div className="mb-lg-5" key={`disclosure-${index}`}>
          {title && <h2 className="disclosure-title">{title}</h2>}
          {subtitle && <p>{subtitle}</p>}
          <p>{paragraph}</p>
        </div>
      ))}
    </section>
  );
};

export default Disclosures;
