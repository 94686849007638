export const DISCLOSURES = [
  {
    paragraph:
      'This is a communication from a debt collector. This is an attempt to collect a debt' +
      ' and any information obtained will be used for that purpose.'
  },
  {
    paragraph:
      'The following jurisdictions require us to furnish you with the following information.' +
      ' These disclosures are requirements of these jurisdictions and do not contain a complete' +
      ' list of the rights all consumers have under city, state, and federal laws.'
  },
  {
    title: 'Colorado Residents',
    paragraph:
      'The address and telephone number of our local office is as follows: 8690 Wolff Court,' +
      ' Suite 110, Westminster, CO 80031; Colorado telephone for Colorado residents only: 303-920-4763.' +
      ' For residents of all other states, please use our toll free number 866-539-7525.'
  },
  {
    title: 'Massachusetts Residents',
    subtitle: 'NOTICE OF IMPORTANT RIGHTS',
    paragraph:
      'You have the right to make a written or oral request that telephone calls regarding your' +
      ' debt not be made to you at your place of employment. Any such oral request will be valid' +
      ' for only ten days unless you provide written confirmation of the request postmarked or' +
      ' delivered within seven days of such request. You may terminate this request by writing' +
      ' to the debt collector.'
  },
  {
    title: 'North Carolina Residents',
    paragraph: 'Spring Oaks Capital, LLC’s North Carolina permit number(s) are as follows: 113996'
  },
  {
    title: 'New York Residents',
    paragraph:
      'If a creditor or debt collector receives a money judgment against you in court, state and' +
      ' federal laws may prevent the following types of income from being taken to pay the debt: ' +
      '1. Supplemental security income, (SSI); 2. Social security; 3. Public assistance (welfare); ' +
      '4. Spousal support, maintenance (alimony) or child support; 5. Unemployment benefits; ' +
      '6. Disability benefits; 7. Workers’ compensation benefits; 8. Public or private pensions; ' +
      '9. Veterans’ benefits; 10. Federal student loans, federal student grants, and federal work ' +
      'study funds; and 11. Ninety percent of your wages or salary earned in the last sixty days.'
  },
  {
    title: 'New York City Residents',
    paragraph:
      'You may also reach us by calling John Cavanagh at 866-539-7554. New York City Department' +
      ' of Consumer Affairs License Number(s): 2097110-DCA'
  },
  {
    title: 'Tennessee Residents',
    paragraph:
      'Spring Oaks Capital, LLC is licensed by the collection service board of the department of' +
      ' commerce and insurance.'
  }
];

export const STATUTE_OF_LIMITATIONS = [
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it.
    If you do not pay the debt, we may report the debt to any credit reporting agency for as long as the law permits this reporting.
    In many circumstances, you can renew the debt and start the time period for the filing of a lawsuit against you if you take specific actions
     such as making certain payment on the debt or making a written promise to pay. You should determine the effect of any actions you take with
      respect to this debt.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt, we
   may continue to report the debt to any credit reporting agency for as long as the law permits this reporting.   In many circumstances, you can
    renew the debt and start the time period for the filing of a lawsuit against you if you take specific actions such as making certain payment
     on the debt or making a written promise to pay. You should determine the effect of any actions you take with respect to this debt.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it and this debt cannot be reported
   to any credit reporting agency. In many circumstances, you can renew the debt and start the time period for the filing of a lawsuit against you
    if you take specific actions such as making certain payment on the debt or making a written promise to pay. You should determine the effect
     of any actions you take with respect to this debt.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
   we may report the debt to any credit reporting agency for as long as the law permits this reporting.   In many circumstances, you can renew
    the debt and start the time period for the filing of a lawsuit against you if you take specific action such as making a written promise to pay.
     You should determine the effect of any actions you take with respect to this debt.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
   we may continue to report the debt to any credit reporting agency for as long as the law permits this reporting. In many circumstances,
    you can renew the debt and start the time period for the filing of a lawsuit against you if you take specific action such as making a written
     promise to pay. You should determine the effect of any actions you take with respect to this debt.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it and this debt cannot be reported
   to any credit reporting agency. In many circumstances, you can renew the debt and start the time period for the filing of a lawsuit against you
    if you take specific action such as making a written promise to pay. You should determine the effect of any actions you take with respect to this debt.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued  for it. If you do not pay the debt,
   we may report the debt to any credit reporting agency for as long as the law permits this reporting.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued  for it. If you do not pay the debt,
   we may continue to report the debt to any credit reporting agency for as long as the law permits this reporting.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it and this debt cannot be reported
   to any credit reporting agency.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
   we may report it to the credit reporting agencies as unpaid for as long as the law permits this reporting.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
   we may continue to report it to the credit reporting agencies as unpaid for as long as the law permits this reporting.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you will not be sued for it, and we will not report it
   to any credit reporting agency`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
   we may report or continue to report it to the credit reporting agencies as unpaid.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it and we will not report it to
   any credit reporting agencies.`,
  `WE ARE REQUIRED BY REGULATION OF THE MASSACHUSETTS ATTORNEY GENERAL TO NOTIFY YOU OF THE FOLLOWING INFORMATION. THIS INFORMATION IS NOT LEGAL ADVICE:
   THIS DEBT MAY BE TOO OLD FOR YOU TO BE SUED ON IT IN COURT. IF IT IS TOO OLD, YOU CANNOT BE REQUIRED TO PAY IT THROUGH A LAWSUIT. TAKE NOTE:
    YOU CAN RENEW THE DEBT AND THE STATUTE OF LIMITATIONS FOR THE FILING OF A LAWSUIT AGAINST YOU IF YOU DO ANY OF THE FOLLOWING:
     MAKE ANY PAYMENT ON THE DEBT; SIGN A PAPER IN WHICH YOU ADMIT THAT YOU OWE THE DEBT OR IN WHICH YOU MAKE A NEW PROMISE TO PAY;
      SIGN A PAPER IN WHICH YOU GIVE UP OR WAIVE YOUR RIGHT TO STOP THE CREDITOR FROM SUING YOU IN COURT TO COLLECT THE DEBT.
       WHILE THIS DEBT MAY NOT BE ENFORCEABLE THROUGH A LAWSUIT, IT MAY STILL AFFECT YOUR ABILITY TO OBTAIN CREDIT OR AFFECT YOUR CREDIT SCORE OR RATING.`,
  `We are required by New Mexico Attorney General Rule to notify you of the following information. This information is not legal advice:
   This debt may be too old for you to be sued on it in court. If it is too old, you can’t be required to pay it through a lawsuit.
    You can renew the debt and start the time for the filing of a lawsuit against you to collect the debt if you do any of the following:
     make any payment of the debt; sign a paper in which you admit that you owe the debt or in which you make a new promise to pay;
      sign a paper in which you give up (“waive”) your right to stop the debt collector from suing you in court to collect the debt.`,
  `We are required by regulation of the New York State Department of Financial Services to notify you of the following information.
   This information is NOT legal advice:
  Your creditor or debt collector believes that the legal time limit (statute of limitations) for suing you to collect this debt may have expired.
   It is a violation of the Fair Debt Collection Practices Act, 15 U.S.C. § 1692 et seq., to sue to collect on a debt for which the statute of
    limitations has expired. However, if the creditor sues you to collect on this debt, you may be able to prevent the creditor from obtaining
     a judgment against you. To do so, you must tell the court that the statute of limitations has expired.
  Even if the statute of limitations has expired, you may choose to make payments on the debt. However, be aware: if you make a payment on the debt,
   admit to owing the debt, promise to pay the debt, or waive the statute of limitations on the debt, the time period in which the debt is enforceable
    in court may start again.
  If you would like to learn more about your legal rights and options, you can consult an attorney or a legal assistance or legal aid organization.`,
  `THE LAW LIMITS HOW LONG YOU CAN BE SUED ON A DEBT. BECAUSE OF THE AGE OF YOUR DEBT, WE WILL NOT SUE YOU FOR IT. IF YOU DO NOT PAY THE DEBT,
   WE MAY CONTINUE TO REPORT IT TO CREDIT REPORTING AGENCIES AS UNPAID FOR AS LONG AS THE LAW PERMITS THIS REPORTING. THIS NOTICE IS REQUIRED BY LAW.`,
  `THE LAW LIMITS HOW LONG YOU CAN BE SUED ON A DEBT. BECAUSE OF THE AGE OF YOUR DEBT, WE  WILL NOT SUE YOU FOR IT, AND WE WILL NOT REPORT IT
   TO ANY CREDIT REPORTING AGENCY.  THIS NOTICE IS REQUIRED BY LAW.`,
  `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
   we may report or continue to report it to the credit reporting agencies as unpaid.`,
  `The law limits how long you can be sued on a debt.  Because of the age of your debt, you cannot be sued for it and we cannot report it to
   any credit reporting agencies.`
];

export const PAYMENT_AUTHORIZATION = {
  ACH: `I authorize Spring Oaks Capital, LLC hereinafter to initiate a single or recurring ACH/electronic debit to my account set forth
  above in the amount(s) and date(s) set forth above. I agree that ACH transactions I authorize comply with all applicable law.
  For recurring payment arrangements, I understand that this authorization will remain in full force and effect until I notify Spring Oaks Capital,
  LLC in writing or by phone that I wish to revoke this authorization. `,
  card: `I authorize Spring Oaks Capital, LLC hereinafter to initiate a single or recurring credit/debit card charge to my account set forth
  above in the amount(s) and date(s) set forth above. I agree that credit/debit card transactions I authorize comply with all applicable law.
  For recurring payment arrangements, I understand that this authorization will remain in full force and effect until I notify Spring Oaks Capital,
  LLC in writing or by phone that I wish to revoke this authorization. `,
  cta: 'To complete the payment process, click the “{buttonText}“ button. ',
  print:
    'It is recommended that you print a copy of this authorization and maintain it for your records.'
};
