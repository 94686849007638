import { createSlice } from '@reduxjs/toolkit';
import {
  paymentFormFulfilled,
  resetPaymentForm,
  selectPaymentMethod,
  savePaymentLast4,
  savePaymentCardType,
  savePaymentToken,
  savePaymentDetails
} from 'state/actions/paymentMethodActions';
import { logout } from 'state/actions/userActions';

const initialState = {
  paymentFormUrl: undefined,
  selectedPaymentMethod: undefined,
  paymentToken: undefined,
  last4: undefined,
  cardType: undefined,
  paymentDetails: undefined
};

const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  extraReducers: {
    [paymentFormFulfilled]: (state, { payload }) => {
      state.paymentFormUrl = payload;
    },
    [selectPaymentMethod]: (state, { payload }) => {
      state.selectedPaymentMethod = payload;
    },
    [savePaymentToken]: (state, { payload }) => {
      state.paymentToken = payload;
    },
    [savePaymentLast4]: (state, { payload }) => {
      state.last4 = payload;
    },
    [savePaymentCardType]: (state, { payload }) => {
      state.cardType = payload;
    },
    [savePaymentDetails]: (state, { payload }) => {
      state.paymentDetails = payload;
    },
    [resetPaymentForm]: () => initialState,
    [logout]: () => initialState
  }
});

export default paymentMethodSlice.reducer;
