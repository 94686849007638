import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import AccountAccordion from 'components/accounts/AccountAccordion';
import { Button, CurrencyText } from 'components/common';
import routes from 'constants/routesPaths';
import { useBreakpoint, useSelectAccountDetails, useAnalytics, useDispatch } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { REPAYMENT_OPTIONS } from 'constants/constants';
import { toFixed } from 'utils/helpers';
import { md } from 'styles/_variables.scss';
import {
  selectRepaymentOption,
  savePlanSummary,
  selectPlan
} from 'state/actions/paymentPlanActions';

const AccountDetails = () => {
  const intl = useIntl();
  const history = useHistory();
  const isDesktop = useBreakpoint(md);
  const [paid, setPaid] = useState(0);
  const { originalBalance, remainingBalance } = useSelectAccountDetails();
  const balance = toFixed(originalBalance - remainingBalance);
  const { trackEvent } = useAnalytics();
  const selectOption = useDispatch(selectRepaymentOption);
  const setPlanSummary = useDispatch(savePlanSummary);
  const selectPaymentPlan = useDispatch(selectPlan);

  const paidStyles = {
    width: paid > 0 ? `${paid}%` : '16px'
  };

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.viewAccountDetailsPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    originalBalance > 0 && setPaid(Math.floor((balance * 100) / originalBalance));
  }, [remainingBalance, originalBalance]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPayInFullClick = () => {
    trackEvent(MIXPANEL_EVENTS.clickPayInFull);
    selectOption(REPAYMENT_OPTIONS.payInFull);
    setPlanSummary(
      intl.formatMessage(
        { id: 'partialPaymentPage.amountSummary' },
        { selectedAmount: `$${remainingBalance}` }
      )
    );
    selectPaymentPlan({
      planTerm: 1,
      planScheduleFrequency: 1
    });
    history.push(routes.setupPlanPayment);
  };

  const payInFullLabel = intl.formatMessage({ id: 'selectRepaymentOption.payInFull' });

  return (
    <div className="account-details-component">
      <div className="account-remaining-balance">
        <div className="d-flex align-items-center pb-2 pb-md-0">
          {intl.formatMessage({ id: 'accounts.remainingBalance' })}
          <div className="balance">
            <CurrencyText value={remainingBalance} />
          </div>
        </div>
        {remainingBalance > 0 && (
          <Button
            buttonStyle="primary"
            size="small"
            color="green"
            title={payInFullLabel}
            ariaLabel={payInFullLabel}
            onClick={onPayInFullClick}
          />
        )}
      </div>
      <div className="progress-details">
        <div className="w-100 pt-2">
          <div className="progress progress-bar-container">
            <div
              className="progress-bar progress-amount"
              aria-valuenow={balance}
              aria-valuemin="0"
              aria-valuemax={originalBalance}
              style={paidStyles}
            />
          </div>
          <div className="d-flex mt-2">
            {intl.formatMessage({ id: 'accounts.paid' })}
            <div className="balance">
              <CurrencyText value={balance} />
            </div>
            {!isDesktop && (
              <>
                <div className="ml-2">{intl.formatMessage({ id: 'accounts.paidOf' })}</div>
                <div className="balance">
                  <CurrencyText value={originalBalance} />
                </div>
              </>
            )}
          </div>
        </div>
        {isDesktop && (
          <div className="account-balance">
            <div className="account-original-balance">
              <div className="balance">
                <CurrencyText value={originalBalance} />
              </div>
              {intl.formatMessage({ id: 'accounts.originalBalance' })}
            </div>
          </div>
        )}
      </div>
      <AccountAccordion />
    </div>
  );
};

export default AccountDetails;
