import React, { useState, useMemo, useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { BackBanner, Button, CurrencyInput, TileButton, ContentContainer } from 'components/common';
import routes from 'constants/routesPaths';
import { selectAmount, savePlanSummary, selectPlan } from 'state/actions/paymentPlanActions';
import { useDispatch, useSelectSelectedPlan, useSelectAccountDetails } from 'hooks';
import { PARTIAL_PAYMENTS_OPTIONS, DEFAULT_PLAN } from 'constants/constants';

const messages = defineMessages({
  amount: { id: 'partialPaymentPage.amount' },
  amountSummary: { id: 'partialPaymentPage.amountSummary' },
  enterAmount: { id: 'partialPaymentPage.enterAmount' },
  percentageAria: { id: 'partialPaymentPage.percentageAria' },
  subtitle: { id: 'partialPaymentPage.subtitle' },
  title: { id: 'partialPaymentPage.title' }
});

const RepaymentOptionPage = () => {
  const intl = useIntl();
  const { remainingBalance } = useSelectAccountDetails();
  const selectPartialAmount = useDispatch(selectAmount);
  const setPlanSummary = useDispatch(savePlanSummary);
  const selectPaymentPlan = useDispatch(selectPlan);
  const { selectedAmount, planSummary } = useSelectSelectedPlan();
  const [selectedPercentage, setSelectedPercentage] = useState();

  useEffect(() => {
    selectPaymentPlan(DEFAULT_PLAN);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onAmountChanged = values => {
    const { formattedValue, value } = values;

    setPlanSummary(
      formattedValue
        ? intl.formatMessage(messages.amountSummary, { selectedAmount: formattedValue })
        : ''
    );

    selectPartialAmount(value);
  };

  const selectPercentage = percentage => {
    selectPartialAmount((remainingBalance * percentage) / 100);
    setSelectedPercentage(percentage);
  };

  const isButtonDisabled = useMemo(
    () => !selectedAmount || selectedAmount > remainingBalance || selectedAmount < 1,
    [selectedAmount, remainingBalance]
  );

  const amountError = useMemo(
    () =>
      (selectedAmount > remainingBalance && 'partialPaymentPage.amountError') ||
      (selectedAmount && selectedAmount < 1 && 'partialPaymentPage.amountErrorOne'),
    [selectedAmount, remainingBalance]
  );

  return (
    <ContentContainer>
      <BackBanner
        ariaLabel={intl.formatMessage({ id: 'createPlan.goBackAria' })}
        title={intl.formatMessage({ id: 'createPlan.goBack' })}
        backTo={routes.repaymentOptions}
      />
      <div className="container">
        <h1 className="page-title">{intl.formatMessage(messages.title)}</h1>
        <h2 className="page-subtitle">{intl.formatMessage(messages.subtitle)}</h2>

        <div className="tile-button-container">
          {PARTIAL_PAYMENTS_OPTIONS.map(percentage => (
            <TileButton
              key={`percentage-${percentage}`}
              label={`${percentage}%`}
              active={selectedPercentage === percentage}
              ariaLabel={intl.formatMessage(messages.percentageAria, { percentage })}
              onClick={() => selectPercentage(percentage)}
              small
            />
          ))}
        </div>

        <p className="page-subtitle">{intl.formatMessage(messages.enterAmount)}</p>

        <div className="row">
          <div className="col-lg-3">
            <CurrencyInput
              name="amount"
              label={intl.formatMessage(messages.amount)}
              onChange={onAmountChanged}
              value={selectedAmount?.toString()}
              onKeyDown={setSelectedPercentage}
              decimalScale={2}
              error={amountError}
            />
          </div>

          <div className="col-lg-9 plan-cta">
            {selectedAmount && <span className="plan-summary">{planSummary}</span>}
            <Button
              buttonStyle="primary"
              size="large"
              color="green"
              title={intl.formatMessage({ id: 'createPlan.continue' })}
              ariaLabel={intl.formatMessage({ id: 'createPlan.continueAria' })}
              isDisabled={isButtonDisabled}
              linkTo={routes.setupPlanPayment}
            />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};

export default RepaymentOptionPage;
