import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { oneOfType, bool, func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import NumberFormat from 'react-number-format';

import { DATEINPUT_FORMAT, DATEINPUT_MASK } from 'constants/constants';

const DateInput = ({
  label,
  value,
  placeholder = '',
  onChange,
  onBlur = () => {},
  error,
  ...props
}) => (
  <div className="input-component">
    {label && (
      <label
        htmlFor={label}
        className={cn('input-component__label', {
          'input-component__label--error': error,
          'input-component__label--empty': isEmpty(value)
        })}
      >
        {label}
      </label>
    )}

    <div className="input-component-container">
      <NumberFormat
        className={cn('input-component-container-content', {
          'input-component-container-content--error': error,
          'input-component-container-content--empty': isEmpty(value)
        })}
        aria-label={label}
        mask={DATEINPUT_MASK}
        value={value}
        placeholder={placeholder}
        onBlur={onBlur}
        format={DATEINPUT_FORMAT}
        onValueChange={onChange}
        {...props}
      />

      {error && (
        <span className="span--error">
          <FormattedMessage id={error} defaultMessage={error} />
        </span>
      )}
    </div>
  </div>
);

DateInput.propTypes = {
  label: string,
  value: string,
  placeholder: string,
  onChange: func.isRequired,
  onBlur: func,
  error: oneOfType([string, bool])
};

export default DateInput;
