import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import { useSession, useAnalytics } from 'hooks';
import { FixedFooter, ContentContainer } from 'components/common';
import Disclosures from 'components/legal/Disclosures';
import routes from 'constants/routesPaths';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';

const DisclosuresPage = () => {
  const intl = useIntl();
  const { authenticated } = useSession();
  const { trackEvent } = useAnalytics();
  const location = useLocation();
  const trackEvents = () => trackEvent(MIXPANEL_EVENTS.acceptDisclosures);

  useEffect(() => {
    window.scrollTo(0, 0);
    trackEvent(MIXPANEL_EVENTS.viewDisclosuresPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentContainer
      noMargin={location.state?.hideMenu}
      className={cn({ scrollable: !location.state?.hideMenu })}
    >
      <div className="container">
        <Disclosures />
        {location.state?.hideMenu &&
          (authenticated ? (
            <FixedFooter
              title={intl.formatMessage({ id: 'footer.continue' })}
              route={routes.index}
              ariaLabel={intl.formatMessage({ id: 'footer.continueAria' })}
              onClick={trackEvents}
            />
          ) : (
            <FixedFooter
              title={intl.formatMessage({ id: 'footer.login' })}
              route={routes.login}
              ariaLabel={intl.formatMessage({ id: 'footer.loginAria' })}
            />
          ))}
      </div>
    </ContentContainer>
  );
};

export default DisclosuresPage;
