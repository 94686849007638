import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { useAnalytics } from 'hooks';
import routes from 'constants/routesPaths';

const { SOC_WEB_CONTACT_US } = process.env;

const LegalInformation = () => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();

  return (
    <>
      <p className="legal-debtCollector">{intl.formatMessage({ id: 'legal.debtCollector' })}</p>
      <Link
        className="legal-knowYourRights"
        aria-label="Go to Disclosures Page"
        to={{ pathname: routes.disclosures, state: { hideMenu: true } }}
        onClick={() => trackEvent(MIXPANEL_EVENTS.clickKnowYourRights)}
      >
        {intl.formatMessage({ id: 'legal.knowYourRights' })}
      </Link>
      <p className="legal-troubleContact">
        {intl.formatMessage(
          { id: 'legal.troubleContact' },
          {
            contactUsLink: (
              <a href={SOC_WEB_CONTACT_US}>{intl.formatMessage({ id: 'legal.contactUs' })}</a>
            )
          }
        )}
      </p>
    </>
  );
};

export default memo(LegalInformation);
