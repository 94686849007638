import { createAction } from '@reduxjs/toolkit';

import paymentPlanService from 'services/paymentPlanService';
import parseError from 'utils/parseError';
import createAsyncThunk from 'utils/createAsyncThunk';

import { getPlanRequestBody, getPaymentDatesRequestBody } from 'utils/helpers';

export const paymentPlanOptions = createAsyncThunk('paymentPlan/options', async accountId => {
  try {
    const data = await paymentPlanService.repaymentOptions(accountId);
    const {
      data: { data: paymentPlanInfo }
    } = data;

    return paymentPlanInfo;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getPaymentPlans = createAsyncThunk('paymentPlan/getPlans', async accountId => {
  try {
    const data = await paymentPlanService.getPaymentPlans(accountId);
    const {
      data: { data: paymentPlansInfo }
    } = data;

    return paymentPlansInfo.paymentPlans ?? [];
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const createPaymentPlan = createAsyncThunk(
  'paymentPlan/createPlan',
  async ({
    accountId,
    paymentToken,
    paymentDetails,
    selectedPaymentMethod,
    selectedRepaymentOption,
    selectedDate,
    selectedPlan,
    selectedFrequency,
    paymentAmount
  }) => {
    try {
      const body = {
        accountId,
        planDetails: getPlanRequestBody(
          paymentToken,
          paymentDetails,
          selectedPaymentMethod,
          selectedRepaymentOption,
          selectedDate,
          selectedPlan,
          selectedFrequency,
          paymentAmount
        )
      };

      const {
        data: {
          data: { planDetails }
        }
      } = await paymentPlanService.createPlan(body);

      return planDetails;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getPlanPaymentDates = createAsyncThunk(
  'paymentPlan/getPlanPaymentDates',
  async ({
    accountId,
    paymentToken,
    selectedPaymentMethod,
    selectedRepaymentOption,
    selectedDate,
    selectedPlan,
    selectedFrequency,
    paymentAmount
  }) => {
    try {
      const body = {
        accountId,
        planScheduleDetails: getPaymentDatesRequestBody(
          paymentToken,
          selectedPaymentMethod,
          selectedRepaymentOption,
          selectedDate,
          selectedPlan,
          selectedFrequency,
          paymentAmount
        )
      };

      const {
        data: {
          data: {
            scheduleDetails: { paymentSchedule }
          }
        }
      } = await paymentPlanService.getPlanPaymentDates(body);

      return paymentSchedule;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const selectRepaymentOption = createAction('paymentPlan/selectRepaymentOption');
export const selectPlan = createAction('paymentPlan/selectPlan');
export const selectDate = createAction('paymentPlan/selectDate');
export const selectFrequency = createAction('paymentPlan/selectFrequency');
export const selectAmount = createAction('paymentPlan/selectAmount');
export const savePlanSummary = createAction('paymentPlan/planSummary');
export const resetRepaymentOption = createAction('paymentPlan/resetRepaymentOption');

export const { fulfilled: paymentOptionsFulfilled } = paymentPlanOptions;
export const { fulfilled: getPaymentPlansFulfilled } = getPaymentPlans;
export const { fulfilled: createPaymentPlanFulfilled } = createPaymentPlan;
export const { fulfilled: getPlanPaymentDatesFulfilled } = getPlanPaymentDates;
