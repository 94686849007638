import { useSelector, shallowEqual } from 'react-redux';

const useSelectAccountDetails = () =>
  useSelector(
    ({ accounts: { details } }) => ({
      originalCreditor: details[0]?.clientName,
      originalBalance: details[0]?.originalBalance,
      remainingBalance: details[0]?.remainingBalance,
      referenceNumber: details[0]?.referenceNumber,
      accountNumber: details[0]?.originalAccountNumber,
      openedDate: details[0]?.accountOpenedDate,
      solDisclosureId: details[0]?.solDisclosureID
    }),
    shallowEqual
  );

export default useSelectAccountDetails;
