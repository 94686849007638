import React from 'react';
import { useIntl } from 'react-intl';

import Status from 'components/routes/Status';
import { Button, ContentContainer } from 'components/common';
import routes from 'constants/routesPaths';
import { useSession } from 'hooks';

import humanStanding from 'assets/images/humanStanding.png';

const NotFoundPage = () => {
  const intl = useIntl();
  const { authenticated } = useSession();

  return (
    <Status code={404}>
      <ContentContainer noMargin={!authenticated}>
        <section className="container error-page">
          <div className="error-page-content order-2 order-sm-1">
            <span className="error-page-number">404</span>
            <h1>{intl.formatMessage({ id: 'notFound.title' })}</h1>
            <p className="text-center">{intl.formatMessage({ id: 'notFound.explanation' })}</p>
            <Button
              linkTo={routes.index}
              buttonStyle="secondary"
              size="medium"
              title={intl.formatMessage({ id: 'notFound.back' })}
            />
          </div>

          <div className="error-page-image order-1 order-sm-2">
            <img
              width="420"
              height="438"
              src={humanStanding}
              alt={intl.formatMessage({ id: 'notFound.altImage' })}
            />
          </div>
        </section>
      </ContentContainer>
    </Status>
  );
};

export default NotFoundPage;
