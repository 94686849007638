import React, { useState, useEffect } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactModal from 'react-modal';
import smoothscroll from 'smoothscroll-polyfill';
import { useIdleTimer } from 'react-idle-timer';

import { logout } from 'state/actions/userActions';
import { useSession, useSelectAccountSuppressed, useDispatch } from 'hooks';
import RouteFromPath from 'components/routes/RouteFromPath';
import routes from '../routes';

const App = () => {
  const { authenticated, info } = useSession();
  const { suppressed } = useSelectAccountSuppressed();
  const [timeout, setTimeout] = useState();
  const logoutRequest = useDispatch(logout);

  ReactModal.setAppElement('#app');
  smoothscroll.polyfill();

  const handleOnIdle = () => {
    setTimeout(undefined);
    alert('Session expired');
    logoutRequest();
  };

  const { reset } = useIdleTimer(
    {
      timeout,
      onIdle: handleOnIdle,
      startManually: true,
      crossTab: {
        emitOnAllTabs: true
      }
    },
    [info]
  );

  useEffect(() => {
    info.tokenExpire && reset() && setTimeout(info.tokenExpire);
  }, [info]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <title>Self-Service Portal | Spring Oaks Capital</title>
        <link href="https://fonts.gstatic.com" rel="preconnect" crossOrigin />
        <html lang="en" />
      </Helmet>
      <BrowserRouter>
        <Switch>
          {routes.map((route, index) => (
            <RouteFromPath
              key={`route${index}`}
              {...route}
              authenticated={authenticated}
              suppressed={suppressed}
            />
          ))}
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
