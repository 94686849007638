import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

import { PAYMENT_FREQUENCY, PLAN_FREQUENCY } from 'constants/constants';

export const parseInputErrors = error => {
  if (!error) {
    return;
  }
  if (Array.isArray(error)) {
    return error[0];
  }
  return error;
};

export const applyQueryParams = (url, params = {}) => {
  if (isEmpty(params)) {
    return url;
  }
  const queryParams = queryString.stringify(params);
  return `${url}?${queryParams}`;
};

export const formatDate = date => {
  return date && date.toISOString().substring(0, 10);
};

const dateRange = (val, max) => {
  let range = val;
  if (val.length === 1 && val[0] > max[0]) {
    range = `0${range}`;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      range = '01';
    } else if (val > max) {
      range = max;
    }
  }

  return range;
};

export const formattedDateInput = val => {
  const month = dateRange(val.substring(0, 2), '12');
  const day = dateRange(val.substring(2, 4), '31');
  const year = val.substring(4, 8);

  return month + (day.length ? `/${day}` : '') + (year.length ? `/${year}` : '');
};

export const toFixed = value => parseFloat(value.toFixed(2));

export const toBase64 = value =>
  `data:application/pdf;base64,${value.replace(/(\r\n|\n|\r)/gm, '')}`;

export const getPlanFrequencyInfo = (frequency, weeklyOptions, biweeklyOptions, monthlyOptions) => {
  let planOption;
  let frequencyId;

  switch (frequency) {
    case PAYMENT_FREQUENCY.biweekly:
      planOption = biweeklyOptions;
      frequencyId = 'paymentFrequency.biweeklyLabel';
      break;
    case PAYMENT_FREQUENCY.monthly:
      planOption = monthlyOptions;
      frequencyId = PAYMENT_FREQUENCY.monthly;
      break;
    case PAYMENT_FREQUENCY.weekly:
    default:
      planOption = weeklyOptions;
      frequencyId = PAYMENT_FREQUENCY.weekly;
      break;
  }

  return {
    planOption,
    frequencyId
  };
};

export const getFrequencyPayload = key => (key === 29 ? 31 : key);

export const getPlanRequestBody = (
  paymentToken,
  paymentDetails,
  selectedPaymentMethod,
  selectedRepaymentOption,
  selectedDate,
  selectedPlan,
  selectedFrequency,
  paymentAmount
) => {
  const isMonthly = selectedPlan?.planScheduleFrequency === PLAN_FREQUENCY.monthly;
  return {
    planStartDate: selectedDate,
    planType: selectedRepaymentOption,
    planTerm: selectedPlan?.planTerm || null,
    planScheduleFrequency: selectedPlan?.planScheduleFrequency || null,
    paymentDayOfWeek: (!isMonthly && selectedFrequency?.key) || null,
    paymentDayOfMonth: (isMonthly && getFrequencyPayload(selectedFrequency?.key)) || null,
    paymentAmount,
    paymentProcessorReturn: {
      ...paymentDetails,
      methodType: selectedPaymentMethod,
      token: paymentToken
    }
  };
};

export const getPaymentDatesRequestBody = (
  paymentToken,
  selectedPaymentMethod,
  selectedRepaymentOption,
  selectedDate,
  selectedPlan,
  selectedFrequency,
  paymentAmount
) => {
  const isMonthly = selectedPlan?.planScheduleFrequency === PLAN_FREQUENCY.monthly;

  return {
    planStartDate: selectedDate,
    planType: selectedRepaymentOption,
    planTerm: selectedPlan?.planTerm || null,
    planScheduleFrequency: selectedPlan?.planScheduleFrequency || null,
    paymentDayOfWeek: (!isMonthly && selectedFrequency?.key) || null,
    paymentDayOfMonth: (isMonthly && getFrequencyPayload(selectedFrequency?.key)) || null,
    paymentAmount,
    methodType: selectedPaymentMethod,
    paymentToken
  };
};

export const getOrdinal = number => {
  number = Number(number);

  if (!number || Math.round(number) !== number) {
    return number;
  }
  const signal = number < 20 ? number : Number(`${number}`.slice(-1));

  switch (signal) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

export const acceptOnlyDigits = event => {
  const isNumberNan = Number.isNaN(Number(event.key));

  isNumberNan && event.preventDefault();

  return !isNumberNan;
};
