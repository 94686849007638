import { createSlice } from '@reduxjs/toolkit';
import {
  loginFulfilled,
  logout,
  updateSession,
  toggleBackBannerSummary
} from 'state/actions/userActions';

const initialState = {
  authenticated: false,
  user: null,
  isBannerSummaryExpanded: false,
  info: {}
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  extraReducers: {
    [loginFulfilled]: (state, { payload }) => {
      state.user = payload;
    },
    [logout]: () => initialState,
    [updateSession]: (state, { payload }) => {
      state.info = payload;
      state.authenticated = true;
    },
    [toggleBackBannerSummary]: state => {
      state.isBannerSummaryExpanded = !state.isBannerSummaryExpanded;
    }
  }
});

export default sessionSlice.reducer;
